import { NavPage } from "./types";
import { Policies } from "../authorization";
import React from "react";
import logo from "../../logo.svg"
import lendEquipment from "../../icons/storage-box.svg";
import Home from "../../components/Home";
import AsyncPage from "./asyncpage";
import strings from "../../strings";
import transferInventory from "../../icons/warehouse.svg";
import CameraPicker from "../../components/CameraPicker";

const profile: NavPage = {
    title: strings.profile.title,
    description: strings.profile.yourData,
    items: [
        {
            image: lendEquipment,
            title: strings.profile.myRentalButtonText,
            menuTitle: strings.profile.myRentalButtonText,
            name: "myRentals",
            target: <AsyncPage page="profile/MyRentals"/>,
            authorization: Policies.lemonsoftUser
        },
        {
            image: transferInventory,
            title: strings.profile.myTransfersButtonText,
            menuTitle: strings.profile.myTransfersButtonText,
            name: "myTransfers",
            target: <AsyncPage page={"profile/MyTransactions"}/>,
            authorization: Policies.lemonsoftUser
        },
        {
            image: logo,
            title: strings.chooseScanningCamera,
            name: "cameraPicker",
            target: <CameraPicker/>,
            authorization: Policies.lemonsoftUser

        },
        {
            image: logo,
            title: strings.profile.goHome,
            name: "Home",
            target: <Home/>,
            authorization: Policies.lemonsoftUser
        }
    ]
};

export default profile;
