import {UserOrder} from "../../store/Warehouse/FoodOrderState";

export enum ActionTypes {
    ADD_USER_ORDER = 'Warehouse/FoodOrder/ADD_USER_ORDER',
    DELETE_USER_ORDER = 'Warehouse/FoodOrder/DELETE_USER_ORDER',
    UPDATE_USER_ORDER = 'Warehouse/FoodOrder/UPDATE_USER_ORDER',
    SET_LOCATIONS = 'Warehouse/FoodOrder/SET_LOCATIONS',
    SET_FOOD_OPTIONS = 'Warehouse/FoodOrder/SET_FOOD_OPTIONS',
    STATE_REPLACED = 'Warehouse/FoodOrder/STATE_REPLACED',
    SET_USER_ORDERS = 'Warehouse/FoodOrder/SET_USER_ORDERS',
    SET_MEAL_ITEMS = 'Warehouse/FoodOrder/SET_MEAL_ITEMS',
}

export interface AddUserOrderAction {
    type: ActionTypes.ADD_USER_ORDER;
    payload: {
        food: string;
        location: string;
        id: number;
        comment: string;
    };
}

interface DeleteUserOrderAction {
    type: ActionTypes.DELETE_USER_ORDER;
    payload: string;
}

interface UpdateUserOrderAction {
    type: ActionTypes.UPDATE_USER_ORDER;
    payload: {
        id: number;
        food: string;
        location: string;
        comment: string;
    };
}

interface SetLocationsAction {
    type: ActionTypes.SET_LOCATIONS;
    payload: Location[];
}

interface SetMealItemsAction {
    type: ActionTypes.SET_MEAL_ITEMS;
    payload: MealItem[];
}

interface SetFoodOptionsAction {
    type: ActionTypes.SET_FOOD_OPTIONS;
    payload: FoodOption[];
}

interface SetUserOrdersAction {
    type: ActionTypes.SET_USER_ORDERS;
    payload: UserOrder[];
}

export type FoodOrderActions =
    | AddUserOrderAction
    | DeleteUserOrderAction
    | UpdateUserOrderAction
    | SetLocationsAction
    | SetFoodOptionsAction
    | SetUserOrdersAction
    | SetMealItemsAction;

export interface Location {
    id: string;
    name: string;
}

export interface MealItem {
    id: string;
    name: string;
}

export interface FoodOption {
    id: string;
    name: string;
}

export const setLocations = (locations: Location[]): SetLocationsAction => ({
    type: ActionTypes.SET_LOCATIONS,
    payload: locations,
});

export const setFoodOptions = (foodOptions: FoodOption[]): SetFoodOptionsAction => ({
    type: ActionTypes.SET_FOOD_OPTIONS,
    payload: foodOptions,
});

export const setUserOrders = (userOrders: UserOrder[]): SetUserOrdersAction => ({
    type: ActionTypes.SET_USER_ORDERS,
    payload: userOrders,
});

export const addUserOrder = (id: number, food: string, location: string, comment: string, date: Date, guest: boolean) => ({
    type: ActionTypes.ADD_USER_ORDER,
    payload: {
        id,
        food,
        location,
        comment,
        date,
        guest,
    },
});

export const updateUserOrder = (id: number, food: string, location: string, comment: string): UpdateUserOrderAction => ({
    type: ActionTypes.UPDATE_USER_ORDER,
    payload: {
        id,
        food,
        location,
        comment,
    },
});

export const deleteUserOrder = (id: string): DeleteUserOrderAction => ({
    type: ActionTypes.DELETE_USER_ORDER,
    payload: id,
});

export const setMealItems = (mealItems: MealItem[]): SetMealItemsAction => ({
    type: ActionTypes.SET_MEAL_ITEMS,
    payload: mealItems,
});
