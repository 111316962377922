import React from 'react';
import {Route, Switch} from 'react-router';
import Callback from '../components/Callback';
import SilentRenew from "../components/SilentRenew";
import Login from "../components/Login";
import {flattenNavRouteItem, NavRouteItem,} from "../config/navigation/helpers";
import {Redirect} from "react-router-dom";
import AppState from "../store/appstate";
import {connect} from "react-redux";

interface StateProps {
    rootRoute: NavRouteItem | null;
    isLoadingUser: boolean;
}

const routes = (props: StateProps) => {
    let itemRoutes: NavRouteItem[] = [];

    if (props.rootRoute) {
        itemRoutes = flattenNavRouteItem(props.rootRoute);
    }

    return (
        <Switch>
            {itemRoutes.map(item => {
                return <Route exact path={item.path} render={item.render} key={item.path}/>
            })}
            <Route exact path="/callback" component={Callback}/>
            <Route exact path="/silent_renew" component={SilentRenew}/>
            <Route exact path="/" component={Login}/>
            {!props.isLoadingUser && <Route render={() => <Redirect to="/" push={false}/>}/>}
        </Switch>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        rootRoute: state.navigation.rootRoute,
        isLoadingUser: state.oidc.isLoadingUser
    }
};

export default connect(mapStateToProps)(routes);
