import {makeStyles, Theme} from "@material-ui/core/styles";

export const toggleButtonStyles = makeStyles((theme: Theme) => ({
    toggleButton: {
        padding: '20px',
        border: "none",
        borderRadius: 0,
        fontSize: "14px",
        marginBottom: "5px",
    },
    toggleButtonContainer: {
        display: "flex",
        flexDirection: "row"
    }
}));
