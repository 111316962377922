import {ActionTypes, RentEquipmentAction} from "../../actions/Warehouse/RentEquipmentActions";
import RentEquipmentState from "./RentEquipmentState";
import {DestinationType} from "../../components/RentEquipmentDestination";

const initialState: RentEquipmentState = {
    currentRental: null,
    destinationProject: null,
    destinationWarehouse: null,
    destinationType: DestinationType.inventory
};

const RentEquipmentReducer = (state: RentEquipmentState = initialState, action: RentEquipmentAction) => {
    switch (action.type) {
        case ActionTypes.RENTAL_CHOSEN:
            return (
                action.rental !== state.currentRental
                && (action.rental && action.rental.id !== state.currentRental?.id))
                ?
                {
                    ...state,
                    currentRental: action.rental,
                    destinationProject: null,
                    destinationWarehouse: null,
                    destinationType: null
                }
                : state;
        case ActionTypes.DESTINATION_TYPE_CHOSEN:
            return (state.destinationType !== action.destinationType) ?  {
                ...state,
                destinationType: action.destinationType,
                destinationWarehouse: null,
                destinationProject:  null
            }
            : state;
        case ActionTypes.DESTINATION_PROJECT_CHOSEN:
            return {
                ...state,
                destinationProject: action.destinationProject
            };
        case ActionTypes.DESTINATION_WAREHOUSE_CHOSEN:
            return {
                ...state,
                destinationWarehouse: action.destinationWarehouse
            };
        case ActionTypes.EQUIPMENT_RENTED:
            return {
                state: initialState
            };
        case ActionTypes.STATE_REPLACED:
            return action.newState ?? state;
        default:
            return state;
    }
};

export default RentEquipmentReducer;
