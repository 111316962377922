import {connect} from "react-redux";
import {CallbackComponentProps} from "redux-oidc";
import userManager from "../utils/userManager";

const Callback = (props: CallbackComponentProps) => {
    // eslint-disable-next-line no-restricted-globals
    props.userManager.signinPopupCallback((new URLSearchParams(location.search).toString())).then();
    return null;
};

const mapStateToProps = () => {
    return {
        userManager
    };
};

export default connect(mapStateToProps)(Callback);
