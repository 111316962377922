import { RefObject, useCallback, useLayoutEffect } from "react";
import Quagga, { QuaggaJSCodeReader } from "@ericblade/quagga2";
import "../styles/quagga.css";

interface ScannerProps {
    onDetected: (code: string) => void;
    scannerRef: RefObject<HTMLDivElement>;
    onScannerReady?: Function;
    cameraId?: string;
    facingMode?: string;
    constraints?: MediaTrackConstraints;
    locator?: object;
    numOfWorkers?: number;
    decoders?: QuaggaJSCodeReader[];
    locate?: boolean;
    area?: { top?: string, right?: string, left?: string, bottom?: string };
}

const getMedian = (arr: number[]) => {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
};

const getMedianOfCodeErrors = (decodedCodes: any[]) => {
    const errors = decodedCodes.filter(x => x.error !== undefined).map(x => x.error);
    console.error(decodedCodes.length);
    return getMedian(errors);
};

const defaultConstraints: MediaTrackConstraints = {
    width: { min: 640, ideal: 1920, max: 4096 },
    height: { min: 480, ideal: 1920, max: 4096 }
};

const defaultLocatorSettings = {
    patchSize: 'medium',
    halfSample: true,
};

const defaultDecoders: QuaggaJSCodeReader[] = ['code_128_reader'];

const Scanner = ({
                     onDetected,
                     scannerRef,
                     onScannerReady,
                     cameraId,
                     facingMode,
                     constraints = defaultConstraints,
                     locator = defaultLocatorSettings,
                     numOfWorkers = navigator.hardwareConcurrency || 4,
                     decoders = defaultDecoders,
                     locate = true,
                     area = undefined
                 }: ScannerProps) => {

    const errorCheck = useCallback((result) => {
        if (!onDetected) {
            return;
        }
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 85% certain that it read correctly, then accept the code.
        if (err < 0.15) {
            onDetected(result.codeResult.code);
        }
    }, [onDetected]);

    const handleProcessed = (result: any) => {
        const drawingCtx = Quagga.canvas.ctx.overlay;
        const drawingCanvas = Quagga.canvas.dom.overlay;
        drawingCtx.font = "24px Arial";
        drawingCtx.fillStyle = 'green';

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, drawingCanvas.clientWidth, drawingCanvas.clientHeight);
                result.boxes.filter((box: any) => box !== result.box).forEach((box: any[]) => {
                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'purple', lineWidth: 2 });
                });
            }
            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: 'blue', lineWidth: 2 });
            }
        }
    };

    useLayoutEffect(() => {
        let ignoreStart = false;
        const init = async () => {
            if (!scannerRef.current || ignoreStart) {
                return;
            }
            const defaultCamera = localStorage.getItem('mySelectedCamera') || '';
            const scannerCameraId = defaultCamera !== '' && defaultCamera !== '0' ? defaultCamera : null;
            scannerRef.current.focus({ preventScroll: true });
            await Quagga.init({
                inputStream: {
                    type: 'LiveStream',
                    // @ts-ignore
                    target: scannerRef.current,
                    constraints: {
                        ...defaultConstraints,
                        ...(scannerCameraId && { deviceId: { exact: scannerCameraId } }),
                        ...(!scannerCameraId && { facingMode }),
                        aspectRatio: { min: 1, max: 2 },
                        facingMode: 'environment'
                    },
                    singleChannel: false,
                    area
                },
                locator,
                numOfWorkers,
                decoder: { readers: decoders, multiple: false },
                locate
            }, async (err) => {
                Quagga.onProcessed(handleProcessed);
                if (err) {
                    return console.log('Error starting Quagga:', err);
                }
                if (scannerRef && scannerRef.current) {
                    await Quagga.start();
                    if (onScannerReady) {
                        onScannerReady();
                    }
                }
            });
            Quagga.onDetected(errorCheck);
        }
        init().then();
        return () => {
            Quagga.stop().then();
            Quagga.offDetected(errorCheck);
            Quagga.offProcessed(handleProcessed);
        };
    }, [
        cameraId,
        onDetected,
        onScannerReady,
        scannerRef,
        errorCheck,
        constraints,
        locator,
        decoders,
        locate,
        facingMode,
        numOfWorkers,
        area
    ]);
    return null;
};

export default Scanner
