import { navigationSetRoot } from "../actions/NavigationActions";
import menuRoot from "../config/navigation";
import { getNavRouteForNavPage } from "../config/navigation/helpers";
import { navigateTo } from "../utils/navigation";
import AppState from "../store/appstate";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { User } from "oidc-client";

const Home = (props: any) => {
    props.redirect(props.user);
    return null;
};

const mapStateToProps = (state: AppState) => {
    return {
        user: state.oidc.user
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
    return {
        redirect: (user: User) => {
            dispatch(navigationSetRoot(getNavRouteForNavPage(user, menuRoot)));
            const navRouteForNavPage = getNavRouteForNavPage(user, menuRoot);
            if (navRouteForNavPage) {
                navigateTo(navRouteForNavPage, true);
            }
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
