import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {reducer as oidcReducer} from "redux-oidc";
import {reducer as uiReducer} from "./uireducer";
import WarehouseReducer from "./Warehouse/WarehouseReducer";
import navigationReducer from "./navigationreducer";
import ProfileReducer from "./Profile/ProfileReducer";

const createRootReducer = (history: any) => combineReducers({
    ui: uiReducer,
    router: connectRouter(history),
    oidc: oidcReducer,
    warehouse: WarehouseReducer,
    navigation: navigationReducer,
    profile: ProfileReducer
});

export default createRootReducer;

