import {MessageType} from "../components/MessageBar";
import React from "react";

export enum UIActionTypes {
    MENU_OPENED = "ui/MENU_OPENED",
    MENU_CLOSED = "ui/MENU_CLOSED",
    PROJECT_SEARCH_CHANGED = "ui/PROJECT_SEARCH_CHANGED",
    MESSAGE_DISPLAYED = "ui/MESSAGE_DISPLAYED",
    SHOW_SPINNER = "ui/SHOW_SPINNER"
}

export interface UIAction {
    type: UIActionTypes;

    [propName: string]: any;
}

export const openMenu = () => {
    return {
        type: UIActionTypes.MENU_OPENED,
    }
};
export const closeMenu = () => {
    return {
        type: UIActionTypes.MENU_CLOSED,
    }
};

export const changeProjectSearch = (value: string): UIAction => {
    return {
        type: UIActionTypes.PROJECT_SEARCH_CHANGED,
        value
    };
};

export const showMessage = (message?: string | React.ReactElement, messageType?: MessageType) => {
    return {
        type: UIActionTypes.MESSAGE_DISPLAYED,
        message,
        messageType
    }
};

export const showSpinner = (show: boolean) => {
    return {
        type: UIActionTypes.SHOW_SPINNER,
        show
    }
}