import React, {useEffect} from 'react'
import {connect} from "react-redux";
import Layout from "./Layout";
import {NavRouteItem} from "../config/navigation/helpers";
import {User} from "oidc-client";
import AppState from "../store/appstate";
import ErrorBoundary from "./ErrorBoundary";
import {navigateSetCurrent, navigateSetMenuItems} from "../actions/NavigationActions";
import {Dispatch} from "redux";
import {navigateTo} from "../utils/navigation";
import MessageBar, {MessageType} from "./MessageBar";
import {closeMenu} from "../actions/UIActions";
import Spinner from "./Spinner";

interface PageRendererProps {
    menuTitle: string;
    parent: NavRouteItem;
    current: NavRouteItem;
    user?: User;
    render: () => any;
    message?: string;
    messageType?: MessageType;
    showSpinner: boolean;
}

interface DispatchFunctions {
    setCurrentRouteItem: (current: NavRouteItem) => void;
    navigateTo: (target: NavRouteItem) => void;
}

const PageRenderer = (props: PageRendererProps & DispatchFunctions) => {
    const redirectToChild = props.current.redirectToSingleChild && props.current.children.length === 1;

    useEffect(() => {
        if (redirectToChild) {
            props.navigateTo(props.current.children[0]);
        } else {
            props.setCurrentRouteItem(props.current);
        }
    });

    if (redirectToChild) {
        return null;
    }

    return (
        <Layout showMenu={true} menuTitle={props.menuTitle}>
             <ErrorBoundary>
                {props.render()}
                 {props.showSpinner && <Spinner/>}
             </ErrorBoundary>
            {props.message ? <MessageBar type={props.messageType} message={props.message}/> : null}
        </Layout>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        user: state.oidc.user,
        message: state.ui.message,
        messageType: state.ui.messageType,
        showSpinner: state.ui.showSpinner
    }
};

function findMenu(current: NavRouteItem): NavRouteItem[] {
    if (current.children.length > 0) {
        return current.children;
    }

    if (current.parent) {
        return findMenu(current.parent);
    }

    return [];
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFunctions => {
    return {
        setCurrentRouteItem: current => {
            dispatch(navigateSetCurrent(current));
            dispatch(closeMenu());
            dispatch(navigateSetMenuItems(findMenu(current)));
        },
        navigateTo: target => {
            navigateTo(target, true);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageRenderer);
