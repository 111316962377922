import Routes from './routes'
import React from "react";
import mainTheme from "./styles/mainTheme";
import { ThemeProvider } from '@material-ui/core/styles';
import VersionCheck from "./components/VersionCheck";

const App = () => {
    return (
        <ThemeProvider theme={mainTheme}>
            <Routes/>
            <VersionCheck/>
        </ThemeProvider>
    );
};

export default App;
