import {Action, Middleware} from "redux";
import AppState from "../store/appstate";

function logPayload(action: any) {
    for (const i in action) {
        if (action.hasOwnProperty(i)) {
            console.log(`\t${i}:`, action[i]);
        }
    }
}

// the middleware handler function
function loggerMiddlewareHandler(store: { getState: () => AppState }, next: (arg0: Action) => void, action: Action) {
    console.log("State before:", store.getState());
    console.log("Action:");
    logPayload(action);
    next(action);
    console.log("State after:", store.getState());
}

// the middleware creator function
export default function createLoggerMiddleware(): Middleware {
    return (store: { getState: () => AppState }) => (next: (arg0: Action) => void) => (action: Action) => {
        return loggerMiddlewareHandler(store, next, action);
    }
}
