import React, {useEffect, useState} from 'react'
import logo from "../logo-white.svg";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import userManager from "../utils/userManager";
import strings from "../strings";
import "./Login.css";
import poster from "../assets/poster.jpg";
// @ts-ignore
import video from "../assets/background.mp4";
import {userFound} from "redux-oidc";
import {replace} from "connected-react-router";
import AppState from "../store/appstate";
import {User} from "oidc-client";

interface StateProps {
    hasUser: boolean;
    userIsLoading: boolean;
}

interface DispatchFunctions {
    onLoginButtonClick: any;
    dispatchUser: (user: User) => void;
}

const Login = (props: StateProps & DispatchFunctions) => {
    const [loginFailed, setLoginFailed] = useState(false);

    useEffect(() => {
        document.body.classList.add('live-background');

        if (!props.hasUser && !props.userIsLoading && !loginFailed) {
            userManager.signinSilent()
                .then((value) => {
                    setLoginFailed(false);
                    props.dispatchUser(value);
                })
                .catch(() => {
                    setLoginFailed(true);
                });
        }

        return () => {
            document.body.classList.remove('live-background');
        }
    });

    if (loginFailed) {
        return (
            <div className={"body login"}>
                <div className="video">
                    <video poster={poster} autoPlay={true} muted={true} loop={true} playsInline={true}>
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>
                <div className="main">
                    <div>
                        <img src={logo} className={"logo"} alt="Sundström"/>
                        <span className={"title"}>{strings.title}</span>
                    </div>
                    <button onClick={props.onLoginButtonClick}>{strings.login}</button>
                </div>
            </div>
        );
    }

    return null;
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        hasUser: state.oidc.user !== null,
        userIsLoading: state.oidc.isLoadingUser
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchFunctions => {
    return {
        onLoginButtonClick: () => {
            userManager.signinPopup()
                .then(value => {
                    dispatch(userFound(value));
                    dispatch(replace({
                        pathname: "/",
                        search: ""
                    }));
                })
                .catch((reason) => {
                    console.error(reason);
                    dispatch(replace({
                        pathname: "/",
                        search: ""
                    }));
                });
        },
        dispatchUser: (user: User) => {
            dispatch(userFound(user));
            dispatch(replace({
                pathname: "/",
                search: ""
            }));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login) as React.ComponentType;
