import React, {ReactNode} from "react";
import alerts from "./Alerts";
import strings from "../strings";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {goBack} from "connected-react-router";

interface OwnState {
    hasError: boolean;
    error?: Error;
}

interface OwnProps {
    children: ReactNode;
}

interface DispatchFunctions {
    onTimeoutError: VoidFunction;
}

class ErrorBoundary extends React.Component<OwnProps & DispatchFunctions> {
    state: OwnState = {
        hasError: false
    };

    static getDerivedStateFromError(error: Error): OwnState {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            error
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error, errorInfo);
        ineum('reportError', error, {
            componentStack: errorInfo.componentStack
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps & DispatchFunctions>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.state.hasError) {
            let message: string = strings.defaultErrorMessage;
            let okCallback: VoidFunction = () => {
                this.setState({
                    hasError: false,
                } as OwnState);
            };

            if (this.state.error?.name === "TimeoutError") {
                message = strings.pageLoadErrorMessage;
                okCallback = this.props.onTimeoutError;
            }

            alerts.error(message, okCallback);
        }
    }

    render() {
        if (this.state.hasError) {
            return null;
        }

        return (
            <>
                {this.props.children}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchFunctions => {
    return {
        onTimeoutError: () => dispatch(goBack())
    };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
