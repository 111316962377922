import {MessageType} from "../components/MessageBar";

export interface UIState {
    menuOpen: boolean;
    projectSearchString: string;
    message?: string;
    messageType?: MessageType;
    showSpinner: boolean;
}

export const initialState: UIState = {
    menuOpen: false,
    projectSearchString: '',
    showSpinner: false
};
