import {Store} from "redux";

export default function observeStore<StoreType, T>(store: Store<StoreType>, select: (arg0: StoreType) => T, onChange: (arg0: T) => void): () => void {
    let currentState: T;

    function handleChange() {
        const nextState = select(store.getState());
        if (nextState !== currentState) {
            currentState = nextState;
            onChange(currentState);
        }
    }

    const unsubscribe = store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
}
