import {Provider, ReactReduxContext} from 'react-redux'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {store, history} from "./store";
import {ConnectedRouter} from "connected-react-router";
// you'll need this for older browsers
import "es6-promise/auto";


ReactDOM.render(
    <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

