const config = {
    api: {
        baseUrl: 'https://api.sundstroms.fi/'
    },
    environment: {
        devMode: 'false',
        versionCheckInterval: 1200000,
        version: 'c0bd5b824a94af808229a6b1c64c43944eb37a66'
    },
    ui: {
        timeouts: {
            messageTimeout: 3500,
        },
        mainColor: '#102694',
        contact: 'Alexander Tupeli'
    },
    identityServer: {
        authority: 'https://idp.sundstroms.fi'
    },
    lunchServer: {
            deadlineConfig: {
                hour: 9,
                minute: 0,
                second: 0,
                millisecond: 0
            }
        },
    blockedInventories: [2, 3],
    blockedInventoriesRentEquipment: [1, 2],
    links: {
        marto: 'https://mail.sundstromaboy.com/remote/marto/mobile/index.php',
        ontime: 'https://t11.norlic.fi/sundstrom/',
        sympa: 'https://www.sympahr.net/index.aspx?y=sundstroms',
        pro24_fi: 'https://www.pro24.fi/Login/Login.aspx?rplogin=4324&rphash=c0d3ce57732f2e62917b9d62233a1758d62025ee8cf91a5d6ef060824170c581&lang=1',
        pro24_sv: 'https://www.pro24.fi/Login/Login.aspx?rplogin=4324&rphash=c0d3ce57732f2e62917b9d62233a1758d62025ee8cf91a5d6ef060824170c581&lang=3',
        ilmoituskanava_fi: 'https://sundstrom.ilmoituskanava.fi/#/',
        ilmoituskanava_sv: 'https://sundstrom.ilmoituskanava.fi/#/?lang=sv'
    }
};

export default config;
