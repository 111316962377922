import { initialState, UIState } from "./uistate";
import { UIAction, UIActionTypes } from "../actions/UIActions";

export const reducer = (state: UIState = initialState, action: UIAction) => {
    switch (action.type) {
        case UIActionTypes.MENU_OPENED:
            return {
                ...state,
                menuOpen: true
            };
        case UIActionTypes.MENU_CLOSED:
            return {
                ...state,
                menuOpen: false
            };
        case UIActionTypes.PROJECT_SEARCH_CHANGED:
            return {
                ...state,
                projectSearchString: action.value,
            };
        case UIActionTypes.MESSAGE_DISPLAYED:
            return {
                ...state,
                message: action.message,
                messageType: action.messageType
            };
        case UIActionTypes.SHOW_SPINNER:
            return {
                ...state,
                showSpinner: action.show
            }
        default:
            return state;
    }
};
