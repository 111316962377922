import { ActionTypes, UserAction } from "../../actions/Warehouse/UserActions";
import UserState from "./UserState";

const initialState: UserState = {
  id: null,
  email: null,
};

const UserReducer = (state: UserState = initialState, action: UserAction) => {
  switch (action.type) {
    case ActionTypes.USER_CHOSEN:
      return action.user;
    case ActionTypes.PROCESS_COMPLETED:
      return {
        state: initialState,
      };
    case ActionTypes.STATE_REPLACED:
      return action.newState ?? state;
    default:
      return state;
  }
};

export default UserReducer;
