import React from 'react';
import {NavRouteItem} from "../config/navigation/helpers";
import {User} from "oidc-client";
import {connect} from "react-redux";
import AppState from "../store/appstate";
import NavItemLink from "./NavItemLink";
import "./NavPageMenu.css";

interface NavPageMenuProps {
    parent: NavRouteItem;
    user?: User | null;
}

const NavPageMenu = (props: NavPageMenuProps) => {
    return (
        <>
            <p>{props.parent.navPage.description}</p>
            <div className="navpage">
                {props.parent.children.map((item: NavRouteItem, i: number) => <NavItemLink item={item} key={i}/>)}
            </div>
        </>
    );
}

const mapStateToProps = (state: AppState) => {
    return {
        user: state.oidc.user
    };
};

export default connect(mapStateToProps)(NavPageMenu);
