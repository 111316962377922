import React from 'react';
import "./NavItemLink.css";
import { NavRouteItem } from "../config/navigation/helpers";
import { navigateTo } from "../utils/navigation";
import { connect } from "react-redux";

interface OwnProps {
    item: NavRouteItem;
}

interface DispatchProps {
    onNavigate: (target: NavRouteItem) => void;
}

const NavItemLink = (props: DispatchProps & OwnProps) => {

    return (
        <div className="navitem" onClick={() => props.onNavigate(props.item)}>
            <img src={props.item.navItem?.image} alt={props.item.navItem?.title}/>
            <span className="title">{props.item.navItem?.title + " >"}</span>
        </div>
    );
}

const mapDispatchToProps = (): DispatchProps => {
    return {
        onNavigate: (target: NavRouteItem) => {
            navigateTo(target);
        }
    };
};

export default connect(null, mapDispatchToProps)(NavItemLink) as React.ComponentType<OwnProps>;
