import React, {useEffect} from "react";
import {ThunkDispatch} from "redux-thunk";
import AppState from "../store/appstate";
import {connect} from "react-redux";
import strings from "../strings";
import ToggleButton from '@material-ui/lab/ToggleButton';
import {PopperProps, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import AutocompletePopper from "./AutocompletePopper";
import {toggleButtonStyles} from "../styles/toggleButtonStyles";
import Inventory from "../BusinessObjects/Inventory";
import Project from "../BusinessObjects/Project";
import {
    setDestinationProject,
    setDestinationType,
    setDestinationWarehouse
} from "../actions/Warehouse/ReturnMaterialActions";
import Api from "../utils/api";
import Search from "./Search";
import CostTypeChoice from "./CostTypeChoice";
import ContractAdditionType from "../BusinessObjects/ContractAdditionType";
import {setContractAdditions, } from "../actions/Warehouse/RegisterMaterialActions";
import ProjectSearchListItem from "./ProjectSearchListItem";

export enum ReturnDestinationType {
    inventory = "inventory",
    project = "project"
}

const defaultChoice = ReturnDestinationType.inventory;

interface DispatchFunctions {
    chooseDestinationType: (destinationType: string) => void;
    setDestinationWarehouse: (destinationWarehouse: Inventory | null) => void;
    setDestinationProject: (destinationProject: Project | null) => void;
    setContractAdditions: (contractAdditions: ContractAdditionType[]) => void;
}

interface StateProps {
    destinationType?: string;
    destinationWareHouse?: Inventory | null;
    destinationProject?: Project | null;
    contractAdditions?: ContractAdditionType[];
}

interface OwnProps {
    sourceProject?: Project | null;
    disabled: boolean;
    destinationInventories?: Inventory[];
    contractAddition: ContractAdditionType | null;
    costType: string;
    chooseCostType: (costType: string) => void;
    setContractAddition: (contractAddition: ContractAdditionType | null) => void;
}

const ReturnDestinationChoice = (props: StateProps & DispatchFunctions & OwnProps) => {
    const classes = toggleButtonStyles();
    const destinationType = props.destinationType ?? defaultChoice;
    

    useEffect(() => {
        const defaultWarehouse = props.destinationInventories?.find((warehouse) => {
            return warehouse.warehouse.number === 1;
        });
        if(destinationType === ReturnDestinationType.inventory){
            props.setDestinationWarehouse(props.destinationWareHouse ?? (defaultWarehouse ?? null));
        }
    }, [props.destinationInventories]);


    useEffect(() => {
        props.setContractAdditions(props.destinationProject?.contractAdditions?? []);
    }, [props.destinationProject]);

    return (
        <div>
            <div className={classes.toggleButtonContainer}>
                <ToggleButton disabled={props.disabled} value={ReturnDestinationType.inventory}
                              className={classes.toggleButton}
                              selected={destinationType === ReturnDestinationType.inventory}
                              onClick={() => {
                                  props.chooseDestinationType(ReturnDestinationType.inventory)
                              }}>
                    {strings.rentEquipmentDestination.warehouse}
                </ToggleButton>
                <ToggleButton disabled={props.disabled} value={ReturnDestinationType.project}
                              className={classes.toggleButton}
                              selected={destinationType === ReturnDestinationType.project}
                              onClick={() => {
                                  props.chooseDestinationType(ReturnDestinationType.project)
                              }}>
                    {strings.project}
                </ToggleButton>
            </div>
            {destinationType === ReturnDestinationType.inventory ?
                <Autocomplete
                    disabled={props.disabled}
                    fullWidth={true}
                    renderInput={(params) => <TextField {...params}
                                                        label={strings.newProductPosition}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            disableUnderline: true
                                                        }}/>}
                    options={props.destinationInventories ? props.destinationInventories : []}
                    getOptionLabel={(option: Inventory) => option.warehouse.name}
                    PopperComponent={(popperProps: PopperProps) => <AutocompletePopper {...popperProps}
                                                                                       className={popperProps.className + " contract-addition"}
                                                                                       children={popperProps.children}/>}
                    onChange={(event, option: Inventory | null) => {
                        props.setDestinationWarehouse(option);
                    }}
                    noOptionsText={strings.noWarehousesFound}
                    value={props.destinationWareHouse}
                    getOptionSelected={(option, value) => option.warehouse.number === value.warehouse.number}
                />
                : <>
                    <Search<Project>
                        disable={props.disabled}
                        delay={1000}
                        onSearchChange={props.setDestinationProject}
                        currentSearchObject={props.destinationProject}
                        barCodeScanner={false}
                        label={strings.newProjectPosition}
                        placeholder={strings.searchProject}
                        startSearch={Api.searchProjects}
                        displayValue={value => value?.name ?? ""}
                        renderOption={(option, {selected}) => (
                            <ProjectSearchListItem option={option}/>
                        )}
                        renderValue={(value) => (
                            <ProjectSearchListItem option={value} />
                        )}
                        filterSearchTypes={(project: Project) => project.id !== props.sourceProject?.id}
                    />
                    <CostTypeChoice
                        costType={props.costType}
                        chooseCostType={props.chooseCostType}
                        contractAddition={props.contractAddition}
                        setContractAddition={props.setContractAddition}
                        currentProject={props.destinationProject?? null}
                    />
                </>}
        </div>

    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchFunctions => {
    return {
        chooseDestinationType: (type: string) => dispatch(setDestinationType(type)),
        setDestinationWarehouse: (destination: Inventory | null) => dispatch(setDestinationWarehouse(destination)),
        setDestinationProject: (destination: Project | null) => dispatch(setDestinationProject(destination)),
        setContractAdditions: (contractAdditions: ContractAdditionType[]) => dispatch(setContractAdditions(contractAdditions))
        
    }
};

const mapStateToProps = (state: AppState): StateProps => {
    const localState = state.warehouse.returnMaterial;
    return {
        destinationType: localState.destinationType,
        destinationWareHouse: localState.destinationWarehouse,
        destinationProject: localState.destinationProject
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnDestinationChoice) as React.ComponentType<OwnProps>;
