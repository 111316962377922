import {NavPage} from "./types";
import strings from "../../strings";
import AsyncPage from "./asyncpage";
import {Policies} from "../authorization";
import React from "react";

import registerOnProject from "../../icons/pipes.svg";
import transferInventory from "../../icons/warehouse.svg";
import lendEquipment from "../../icons/storage-box.svg";
import receiveDelivery from "../../icons/product-loading.svg";
import returnMaterialIcon from "../../icons/returnmaterialIcon.svg";
import foodIcon from "../../icons/foodicon.svg";

const warehouse: NavPage = {
    title: strings.pages.warehouse.title,
    items: [
        {
            image: registerOnProject,
            title: strings.warehouse.register,
            menuTitle: strings.warehouse.registerMenuTitle,
            name: "register",
            target: <AsyncPage page="warehouse/RegisterMaterial"/>,
            //target: Test2,
            //target: <Test userName="Kalle Anka" />,
            authorization: Policies.lemonsoftUser
        },
        {
            image: transferInventory,
            title: strings.warehouse.transfer,
            menuTitle: strings.warehouse.transferMenuTitle,
            name: "transfer",
            target: <AsyncPage page="warehouse/Transfer"/>,
            //target: Test2,
            //target: <Test userName="Kalle Anka" />,
            authorization: Policies.lemonsoftUser
        },
        {
            image: lendEquipment,
            title: strings.warehouse.lend,
            name: "lend",
            target: <AsyncPage page="warehouse/RentEquipment"/>,
            //target: Test2,
            //target: <Test userName="Kalle Anka" />,
            authorization: Policies.lemonsoftUser
        },
        {
            image: receiveDelivery,
            title: strings.warehouse.receive,
            name: "receive",
            target: <AsyncPage page="warehouse/ReceiveDelivery"/>,
            //target: Test2,
            //target: <Test userName="Kalle Anka" />,
            authorization: Policies.lemonsoftReceiverUser
        },
        {
            image: returnMaterialIcon,
            title: strings.warehouse.returnMaterial,
            name: "return",
            target: <AsyncPage page="warehouse/Return"/>,
            //target: Test2,
            //target: <Test userName="Kalle Anka" />,
            authorization: Policies.lemonsoftUser
        },
        {
            image: foodIcon,
            title: strings.warehouse.orderFood,
            name: "food",
            target: <AsyncPage page="warehouse/FoodOrdering"/>,
            //target: Test2,
            //target: <Test userName="Kalle Anka" />,
            authorization: Policies.lunchOrderingUser
        }
    ]
};

export default warehouse;
