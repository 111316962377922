import React from "react";
import { ThunkDispatch } from "redux-thunk";
import AppState from "../store/appstate";
import { connect } from "react-redux";
import strings from "../strings";
import {
    setDestinationProject,
    setDestinationType,
    setDestinationWarehouse
} from "../actions/Warehouse/RentEquipmentActions";
import ToggleButton from '@material-ui/lab/ToggleButton';
import { PopperProps, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import "./CostTypeChoice.css"
import AutocompletePopper from "./AutocompletePopper";
import Rental, { WarehouseInfoShelf } from "../BusinessObjects/RentalInterfaces";
import Project from "../BusinessObjects/Project";
import { toggleButtonStyles } from "../styles/toggleButtonStyles";
import Search from "./Search";
import Api from "../utils/api";
import { User } from "oidc-client";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ProjectSearchListItem from "./ProjectSearchListItem";
import config from "../config";

export enum DestinationType {
    project = "project",
    inventory = "inventory",
    personal = "personal"
}

interface OwnProps {
    defaultDestinationType: DestinationType;
}

interface DispatchFunctions {
    chooseDestinationType: (destinationType: DestinationType) => void;
    chooseDestinationWarehouse: (destinationWarehouse: WarehouseInfoShelf | null) => void;
    chooseDestinationProject: (destinationProject: Project | null) => void;
}

interface StateProps {
    destinationType?: string;
    destinationWarehouse: WarehouseInfoShelf | null;
    destinationProject: Project | null;
    currentRental: Rental | null;
    oidcUser?: string;
    user?: string | null;
}

const RentEquipmentDestination = (props: OwnProps & StateProps & DispatchFunctions) => {
    const classes = toggleButtonStyles();
    const destinationType = props.destinationType ?? props.defaultDestinationType;

    const destination = () => {
        switch (destinationType) {
            case DestinationType.inventory: {
                const destinationList = props.currentRental
                    ? props.currentRental.potentialWarehouses
                        .filter((warehouse) =>
                            config.blockedInventoriesRentEquipment.indexOf(warehouse.number) < 0)
                        .filter((warehouse: WarehouseInfoShelf) =>
                            warehouse.number !== props.currentRental?.currentLocation?.warehouse?.number)
                    : [];

                return (
                    <Autocomplete
                        disabled={!props.currentRental}
                        fullWidth={true}
                        options={destinationList}
                        getOptionLabel={(option) => option.name}
                        noOptionsText={strings.rentEquipmentDestination.noPossibleDestinationWarehousesFound}
                        onChange={(_event, option: WarehouseInfoShelf | null) => props.chooseDestinationWarehouse(option)}
                        value={props.destinationWarehouse}
                        renderInput={(params) => (
                            <TextField {...params}
                                label={strings.rentEquipmentDestination?.destinationWarehouse}
                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                            />
                        )}
                        PopperComponent={(popperProps: PopperProps) => (
                            <AutocompletePopper {...popperProps}
                                className={popperProps.className + " contract-addition"}
                                children={popperProps.children}
                            />
                        )}
                    />
                );
            }
            case DestinationType.project: {
                return <Search<Project>
                    delay={1000}
                    onSearchChange={props.chooseDestinationProject}
                    currentSearchObject={props.destinationProject}
                    barCodeScanner={false}
                    disable={!props.currentRental}
                    filterSearchTypes={project => project.id !== props.currentRental?.currentLocation?.project?.id}
                    label={strings.rentEquipmentDestination.destinationProject}
                    placeholder={strings.searchProject}
                    startSearch={Api.searchProjects}
                    displayValue={value => value?.name ?? ""}
                    renderOption={(option, _renderOptionState) => <ProjectSearchListItem option={option}/>}
                    renderValue={(value) => <ProjectSearchListItem option={value} />}
                />
            }
            case DestinationType.personal: {
                return (
                    <Box border={1} padding={3}>
                        <Typography>
                            {props.user ? props.user : props.oidcUser}
                        </Typography>
                    </Box>
                );
            }
            default: {
                return null
            }
        }
    };

    return (
        <div>
            <div className={classes.toggleButtonContainer}>
                <ToggleButton className={classes.toggleButton}
                    value={DestinationType.project}
                    selected={destinationType === DestinationType.project}
                    onClick={() => props.chooseDestinationType(DestinationType.project)}
                >
                    {strings.rentEquipmentDestination?.project}
                </ToggleButton>
                <ToggleButton className={classes.toggleButton}
                    value={DestinationType.personal}
                    selected={destinationType === DestinationType.personal}
                    onClick={() => props.chooseDestinationType(DestinationType.personal)}
                >
                    {strings.rentEquipmentDestination?.personal}
                </ToggleButton>
                <ToggleButton className={classes.toggleButton}
                    value={DestinationType.inventory}
                    selected={destinationType === DestinationType.inventory}
                    onClick={() => props.chooseDestinationType(DestinationType.inventory)}
                >
                    {strings.rentEquipmentDestination?.warehouse}
                </ToggleButton>
            </div>
            {destination()}
        </div>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchFunctions => {
    return {
        chooseDestinationType: (type: DestinationType) => dispatch(setDestinationType(type)),
        chooseDestinationWarehouse: (type: WarehouseInfoShelf | null) => dispatch(setDestinationWarehouse(type)),
        chooseDestinationProject: (type: Project | null) => dispatch(setDestinationProject(type as Project))
    }
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        destinationType: state.warehouse.rentEquipment.destinationType,
        destinationWarehouse: state.warehouse.rentEquipment.destinationWarehouse,
        destinationProject: state.warehouse.rentEquipment.destinationProject,
        currentRental: state.warehouse.rentEquipment.currentRental,
        oidcUser: state.oidc.user?.profile.name,
        user: state.warehouse.selectedUser?.email
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentEquipmentDestination);
