import { IdpUser } from "../../BusinessObjects/User";

export enum ActionTypes {
  USER_CHOSEN = "components/UserDropdown/USER_CHOSEN",
  PROCESS_COMPLETED = "components/UserDropdown/PROCESS_COMPLETED",
  STATE_REPLACED = "components/UserDropdown/STATE_REPLACED",
}

export interface UserAction {
  type: ActionTypes;
  [propName: string]: any;
}

export const setUser = (user: IdpUser | null): UserAction => {
  return {
    type: ActionTypes.USER_CHOSEN,
    user,
  };
};

export const resetUser = (): UserAction => {
  return {
    type: ActionTypes.PROCESS_COMPLETED,
  };
};
