const promiseTimeout = function (ms: number, promise: Promise<any>) {
    let id: NodeJS.Timeout;
    const timeout = new Promise((resolve, reject) => {
        id = setTimeout(() => {
            reject({
                message: `Timed out in ${ms} ms.`,
                name: "TimeoutError"
            } as Error);
        }, ms);
    });

    return Promise.race([promise, timeout])
        .finally(() => {
            clearTimeout(id);
        });
};

export default promiseTimeout;
