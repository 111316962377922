import React from "react";
import {
  Typography,
  Link,
  Divider,
  Icon,
  ListItem,
  ListItemText,
  ListItemIcon,
  withStyles,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import strings from "../strings";
import config from "../config";
import "./ExternalLinks.css";

const ColoredLink = withStyles({
  root: {
    color: "#102695",
  },
})(Link);

const language = strings.getLanguage();

const linksList = [
  {
    title: strings.links.ontime,
    url: config.links.ontime,
  },
  {
    title: strings.links.marto,
    url: config.links.marto,
  },
  {
    title: strings.links.pro24,
    url: language === "sv" ? config.links.pro24_sv : config.links.pro24_fi,
  },
  {
    title: strings.links.sympa,
    url: config.links.sympa,
  },
  {
    title: strings.links.ilmoituskanava,
    url:
      language === "sv"
        ? config.links.ilmoituskanava_sv
        : config.links.ilmoituskanava_fi,
  },
];

const LinksSection = () => {
  return (
    <div className="links-container">
      <Typography variant="h6" align="left">
        {strings.otherTools}
      </Typography>

      <Divider />

      {linksList.map((i) => (
        <Typography className="link-item" variant="subtitle1" key={i.title}>
          <ColoredLink href={i.url} target="_blank" rel="noreferrer">
            {i.title}
          </ColoredLink>
          <Icon className="link-icon">
            <OpenInNewIcon fontSize="small" />
          </Icon>
        </Typography>
      ))}
    </div>
  );
};

const LinksList = () => {
  return (
    <>
      {linksList.map((i) => (
        <ListItem
          button
          onClick={() => window.open(i.url, "_blank")}
          key={i.title}
        >
          <ListItemText className="list-text" primary={i.title} />
          <ListItemIcon>
            <OpenInNewIcon className="list-icon" fontSize="small" />
          </ListItemIcon>
        </ListItem>
      ))}
    </>
  );
};

export { LinksSection, LinksList };
