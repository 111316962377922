import { store } from "../../store";
import WarehouseState from "../../store/Warehouse/WarehouseState";
import { ActionTypes as TransferActionTypes } from "./TransferActions";
import { ActionTypes as RegisterActionTypes } from "./RegisterMaterialActions";
import { ActionTypes as ReturnActionTypes } from "./ReturnMaterialActions";
import { ActionTypes as ReceiveDeliveryActionTypes } from "./ReceiveDeliveryActions";
import { ActionTypes as RentEquipmentActionTypes } from "./RentEquipmentActions";
import { ActionTypes as FoodOrderActionTypes } from "./FoodOrderActions";

export const replaceWarehouseState = (newState: WarehouseState) => {
    store.dispatch({
        type: TransferActionTypes.STATE_REPLACED,
        newState: newState.transfer,
    });
    store.dispatch({
        type: RegisterActionTypes.STATE_REPLACED,
        newState: newState.registerMaterial,
    });
    store.dispatch({
        type: ReturnActionTypes.STATE_REPLACED,
        newState: newState.returnMaterial,
    });
    store.dispatch({
        type: ReceiveDeliveryActionTypes.STATE_REPLACED,
        newState: newState.receiveDelivery,
    });
    store.dispatch({
        type: RentEquipmentActionTypes.STATE_REPLACED,
        newState: newState.rentEquipment,
    });
    store.dispatch({
        type: FoodOrderActionTypes.STATE_REPLACED,
        newState: newState.foodOrder,
    });
};
