import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import strings from "../strings";
import Alerts from "./Alerts";

interface OwnProps {
    open: boolean;
    handleClose: () => void
    addType: (name: string) => void
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children?: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const AddCostTypeChoice = (props: OwnProps) => {
    const [newCostType, setNewCostType] = useState('');
    
    const handleChange = (e: React.ChangeEvent<{}>) => {
        const element = e.currentTarget as HTMLInputElement;
        setNewCostType(element.value);
    }

    const handleSave = () => {
      const trimmedCostType = newCostType.trim();
      if (trimmedCostType.length > 1) props.addType(trimmedCostType);
      else Alerts.error(strings.contractAdditionTooShort, () => {return true;})
    }

    return (
        <Dialog open={props.open} TransitionComponent={Transition}        >
        <DialogTitle>{strings.addNewContractAdditionType}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {strings.choiceNotFoundAddNew}
          </DialogContentText>
          <TextField
            InputProps={{ disableUnderline: true }}
            onChange={handleChange}
            autoFocus
            label={strings.addNewContractAdditionType}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} variant={'contained'}>{strings.cancel}</Button>
          <Button onClick={handleSave} variant={'contained'} color={'primary'}>{strings.add}</Button>
        </DialogActions>
      </Dialog>
    );
}

export default AddCostTypeChoice;
