import {NavPage} from "./types";
import strings from "../../strings";
import logo from "../../logo-white.svg";
import profile from "./profile";

const profileRoot: NavPage = {
    title: strings.pages.root.title,
    description: strings.pages.root.description,
    items: [
        {
            image: logo,
            title: strings.profile.title,
            name: "profile",
            target: profile
        }
    ],
    redirectToSingleChild: true
};

export default profileRoot;
