import loadable from "@loadable/component";
import React, {useEffect, useState} from "react";
import promiseTimeout from "../../utils/promiseTimeout";
import Spinner from "../../components/Spinner";

const DelayedFallback = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 300);
        return () => {
            clearTimeout(timeout)
        }
    }, []);

    return (
        <>
            {show && <Spinner/>}
        </>
    );
}

interface Props {
    page: string;
}

const AsyncPage = loadable<Props>(props => promiseTimeout(5000, import(`../../apps/${props.page}`)), {
    cacheKey: props => props.page,
    fallback: <DelayedFallback/>
});

export default AsyncPage;
