import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { AppBar, Toolbar, Box, Typography, IconButton, Divider,
    Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import { closeMenu, openMenu } from "../actions/UIActions";
import AppState from "../store/appstate";
import userManager from "../utils/userManager";
import { getNavRouteForNavPage, NavRouteItem } from "../config/navigation/helpers";
import { navigateTo } from "../utils/navigation";
import { ThunkDispatch } from "redux-thunk";

import strings from "../strings";
import logo from "../logo-white.svg";
import { User } from "oidc-client";
import { navigationSetRoot } from "../actions/NavigationActions";
import profileRoot from "../config/navigation/profileRoot";
import menuRoot from "../config/navigation";
import { LinksList } from './ExternalLinks';

const useStyles = makeStyles((theme: Theme) => ({
    menuButtonBackground: {
        alignSelf: "stretch"
    },
    menuButton: {
        borderRadius: '2px',
    },
    title: {
        flexGrow: 1,
        marginLeft: '1em',
    },
    logo: {
        width: "3em",
        display: "flex",
    },
    drawerPaper: {
        width: '100%',
    },
    drawerToolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

interface StateProps {
    menuOpen: boolean;
    rootRoute: NavRouteItem | null;
    currentRoute: NavRouteItem | null;
    navItems: NavRouteItem[];
    user: User | undefined;
}

interface OwnProps {
    title?: string;
}

interface DispatchProps {
    handleMenu: any;
    handleClose: any;
    onLogoutButtonClick: any;
    onNavigate: (target: NavRouteItem) => void;
    onProfile: (user?: User) => void;
    onHome: (user?: User) => void;
}

const PRIMARY_BG = "primary.main";

const MenuAppBar = (props: StateProps & DispatchProps & OwnProps) => {
    const classes = useStyles();
    const contrastText = "primary.contrastText";
    const profileButton = props.user ?
        <Box style={{ marginRight: "1px" }} bgcolor={PRIMARY_BG} color={contrastText}
             className={classes.menuButtonBackground}>
            <IconButton style={{ fontSize: "20px" }} className={`${classes.menuButton} far fa-user`}
                        onClick={() => props.onProfile(props.user)}/>
        </Box> : null;
    return (
        <div>
            <AppBar position="static">
                <Toolbar disableGutters={true} variant={"dense"}>
                    <Box bgcolor={PRIMARY_BG} className={classes.menuButtonBackground}
                         onClick={() => props.rootRoute && props.onHome(props.user)}><img
                        className={classes.logo} src={logo} alt="Sundström"/></Box>
                    <Typography variant="subtitle1" className={classes.title} align={'left'}>
                        {props.title}
                    </Typography>
                    {profileButton}
                    <Box bgcolor={PRIMARY_BG} color={contrastText} className={classes.menuButtonBackground}>
                        <IconButton className={classes.menuButton}
                                    aria-label="menu" onClick={props.handleMenu}>
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="persistent"
                anchor="right"
                open={props.menuOpen}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <AppBar position={'relative'} className={classes.drawerToolbar}>
                    <Typography variant="subtitle1" className={classes.title} align="left">
                        {strings.menu.title}
                    </Typography>
                    <Box bgcolor={PRIMARY_BG} color={contrastText} className={classes.menuButtonBackground}>
                        <IconButton onClick={props.handleClose} className={classes.menuButton} color="inherit">
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </AppBar>
                <List>
                    {props.navItems && props.navItems.map((item: NavRouteItem, i: number) => {
                        return (
                            <ListItem button key={i} onClick={() => props.onNavigate(item)}>
                                <ListItemText primary={item.navItem?.title}/>
                            </ListItem>
                        );
                    })}
                    <Divider component="li"/>
                    <LinksList />
                    <Divider component="li"/>
                    <ListItem button>
                        <ListItemText primary={strings.settings}/>
                    </ListItem>
                    <ListItem button onClick={props.onLogoutButtonClick}>
                        <ListItemText primary={strings.logOut}/>
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        menuOpen: state.ui.menuOpen,
        rootRoute: state.navigation.rootRoute,
        currentRoute: state.navigation.currentRoute,
        navItems: state.navigation.menuRoutes,
        user: state.oidc.user
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
    return {
        handleMenu: () => dispatch(openMenu()),
        handleClose: () => dispatch(closeMenu()),
        onLogoutButtonClick: () => userManager.signoutRedirect().then(),
        onNavigate: (target: NavRouteItem) => {
            navigateTo(target);
            dispatch(closeMenu());
        },
        onProfile: (user?: User) => {
            if (!user) {
                return;
            }
            const root = getNavRouteForNavPage(user, profileRoot);
            dispatch(navigationSetRoot(root));
            root && navigateTo(root);
        },
        onHome: (user?: User) => {
            if (!user) {
                return
            }
            const root = getNavRouteForNavPage(user, menuRoot);
            dispatch(navigationSetRoot(root));
            root && navigateTo(root);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar) as React.ComponentType<OwnProps>;
