import React, { useEffect, useState } from "react";
import { fetchVersion, VersionString } from "../thunks/checkVersion";
import { Dialog, Theme } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import strings from "../strings";
import config from "../config";
import * as serviceWorker from "../serviceWorker";

interface State {
    timer: number;
    showDialog: boolean;
    waitingWorker: ServiceWorker | null;
    currentRegistration: ServiceWorkerRegistration | null;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: "1em",
        marginRight: "1em",
        padding: theme.spacing(3),
        marginBottom: 0,
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        borderBottom: "1px black solid",
        color: theme.palette.getContrastText(theme.palette.primary.main)
    }
}));

const VersionCheck = (props: any) => {

    const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
        updateState({
            ...state,
            waitingWorker: registration && registration.waiting,
            showDialog: true
        })
    };

    const classes = useStyles();

    const [state, updateState] = useState({
        timer: 0,
        showDialog: false,
        waitingWorker: null,
        currentRegistration: null,
    } as State);

    const handleReload = () => {
        updateState({ ...state, showDialog: false });
        const waitingWorker = state.waitingWorker;
        if (waitingWorker) {
            waitingWorker.postMessage({ type: "SKIP_WAITING" });
            waitingWorker.addEventListener('statechange', (e: any) => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            })
        }
    };

    const setServiceWorker = (registration: ServiceWorkerRegistration) => {
        registration.onupdatefound = (e) => {
            updateState({ ...state, showDialog: true })
        };
        updateState({ ...state, currentRegistration: registration });
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onServiceWorkerUpdate, onSuccess: setServiceWorker })
    }, []);

    if (state.timer === 0) {
        updateState({
            ...state, timer: window.setInterval(() => {
                fetchVersion().then((v: VersionString) => {
                    if (config.environment.version !== v.version) {
                        state.currentRegistration && state.currentRegistration.update();
                        window.location.reload();
                    }
                })
            }, config.environment.versionCheckInterval)
        })
    }

    return (
        <Dialog
            open={state.showDialog}
            disableBackdropClick={true}
            maxWidth={"lg"}
        >
            <DialogTitle
                className={classes.title}
            >
                {strings.yourExperienceHasBeenImproved}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.root}>
                    {strings.updateDialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    fullWidth={false}
                    color={"primary"}
                    onClick={handleReload}
                >
                    {strings.ok}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default VersionCheck;
