import {NavRouteItem} from "../config/navigation/helpers";

export enum NavigationActionType {
    NAVIGATION_ROOT_LOADED = "NAVIGATION_ROOT_LOADED",
    NAVIGATION_UPDATED = "NAVIGATION_UPDATED",
    NAVIGATION_MENU_UPDATED = "NAVIGATION_MENU_UPDATED"
}

export interface NavigationAction {
    type: NavigationActionType;
    [propName: string]: any;
}

export interface NavigationRootUpdateAction extends NavigationAction {
    payload: NavRouteItem;
}

export interface NavigationUpdateAction extends NavigationAction {
    payload: NavRouteItem;
}

export interface NavigationMenuUpdateAction extends NavigationAction {
    payload: NavRouteItem[];
}

export const navigationSetRoot = (root: NavRouteItem | null): NavigationAction => {
    return {
        type: NavigationActionType.NAVIGATION_ROOT_LOADED,
        payload: root
    } as const;
};

export const navigateSetCurrent = (target: NavRouteItem | null): NavigationAction => {
    return {
        type: NavigationActionType.NAVIGATION_UPDATED,
        payload: target
    } as const;
};

export const navigateSetMenuItems = (items: NavRouteItem[]): NavigationAction => {
    return {
        type: NavigationActionType.NAVIGATION_MENU_UPDATED,
        payload: items
    } as const;
};
