import React, { ReactNode, useEffect, useRef, useState } from "react";
import Autocomplete, { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "./SearchProject.css"
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Scanner from "./Scanner";

const defaultDelay = 1000;

interface OwnProps<T> {
    onSearchChange: (searchType: T | null) => void;
    currentSearchObject?: T | null;
    delay?: number;
    startSearch: (searchValue: string) => Promise<T[]>;
    barCodeScanner: boolean;
    label: string;
    placeholder: string;
    filterSearchTypes?: (searchType: T) => boolean;
    disable?: boolean;
    renderOption?: (option: T, renderOptionState: AutocompleteRenderOptionState) => ReactNode;
    displayValue: (value?: T | null) => string;
    renderValue?: (value: T) => JSX.Element;
}

let timer: any = 0;

const Search = <T, >(props: OwnProps<T>) => {

    const [searchString, setSearchString] = useState('');
    const [searchResult, setSearchResult] = useState([] as T[]);
    const [lastSearch, setLastSearch] = useState(props.currentSearchObject);
    const [scanning, setScanning] = useState(false);
    const [focus, setFocus] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        const focusInHandler = () => setFocus(true);
        const focusOutHandler = () => setFocus(false);
        if (inputRef.current) {
            inputRef.current?.addEventListener('focusin', focusInHandler)
            inputRef.current?.addEventListener('focusout', focusOutHandler)
            inputRef.current.focus();
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener("focusin", focusInHandler);
                inputRef.current.removeEventListener("focusout", focusOutHandler);
            }
        }
    }, [inputRef?.current])

    const scannerRef = useRef<HTMLDivElement>(null);

    const search = async (value: string) => {
        const results = await props.startSearch(value);
        setSearchResult(results);
        if (results.length === 1) {
            setSearchString(props.displayValue(results[0]));
            props.onSearchChange(results[0]);
            inputRef.current?.blur();
        }
    }
    const scanBarcode = () => {
        setScanning(!scanning);
    };
    const currentDisplayValue = props.displayValue(props.currentSearchObject);
    useEffect(() => {
        clearTimeout(timer);
        if (searchString.match(/\S{2,}/) && searchString !== currentDisplayValue) {
            timer = setTimeout(
                async () => {
                    await search(searchString);
                }, props.delay ?? defaultDelay);
        }
    }, [searchString, props.delay, props.currentSearchObject]);

    if (props.currentSearchObject !== lastSearch) {
        if (!props.currentSearchObject && lastSearch) {
            setSearchString('');
            setSearchResult([]);
        }
        if (props.currentSearchObject) {
            setSearchResult([props.currentSearchObject]);
        }
        setLastSearch(props.currentSearchObject);

    } else if (props.currentSearchObject && searchResult.length === 0) {
        setSearchResult([props.currentSearchObject]);
    }

    useEffect(() => {
        if (props.currentSearchObject && searchString !== currentDisplayValue) {
            setSearchString(currentDisplayValue);
        }
    }, [currentDisplayValue]);

    useEffect(() => {
        setSearchString(props.displayValue(props.currentSearchObject as T));
    }, [focus]);

    const keyPressed = async (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            clearTimeout(timer);
            await search(searchString);
        }
    }

    if (props.currentSearchObject && props.renderValue && !editMode) {
        return (<div className="project-render-value  MuiInput-input" onClick={() => setEditMode(!editMode)}>
                    <span
                        className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                    >
                        {props.label}</span>
            {props.renderValue(props.currentSearchObject)}
        </div>)
    }

    return (
        <div>
            <Autocomplete
                disableClearable
                forcePopupIcon={false}
                freeSolo={true}
                fullWidth={true}
                filterOptions={options => options}
                onInputChange={(_event, value) => {
                    if (!props.disable) {
                        setSearchString(value);
                    }
                }}
                onChange={(event, option?: any) => {
                    if (option?.id) {
                        setSearchString(props.displayValue(option as T));
                        props.onSearchChange(option as T)
                        setEditMode(false);
                    } else {
                        setSearchString('');
                        props.onSearchChange(null);
                        setEditMode(false);
                    }
                }}
                inputValue={searchString}
                options={searchResult.filter(props.filterSearchTypes ? props.filterSearchTypes : () => true)}
                getOptionLabel={(option: T) => `${props.displayValue(option)}`}
                onFocus={(event) => scanning && event.stopPropagation()}
                onBlur={(event) => setEditMode(false)}
                renderInput={
                    (params) =>
                        <TextField {...params}
                                   onKeyDown={keyPressed}
                                   inputRef={inputRef}
                                   InputProps={{
                                       ...params.InputProps,
                                       readOnly: scanning,
                                       disableUnderline: true,
                                       endAdornment: props.barCodeScanner ?
                                           <InputAdornment position="end">
                                               <IconButton
                                                   className={scanning ? "fa-stack" : ''}
                                                   onClick={(event) => {
                                                       scanBarcode();
                                                       event.stopPropagation();
                                                   }}
                                               >
                                                   <i className={"fas fa-barcode-alt" + (scanning ? " fa-stack-1x" : "")}/>
                                                   {scanning ? <i
                                                       className={"fas fa-ban fa-stack-1x"}
                                                       style={{ color: "Tomato" }}
                                                   /> : null}
                                               </IconButton>
                                           </InputAdornment> : null
                                   }}
                                   label={props.label}
                                   placeholder={props.placeholder}
                        />}
                renderOption={props.renderOption}
                disabled={props.disable}
            />
            <div
                ref={scannerRef}
                style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    border: '3px solid red',
                    display: scanning ? 'inherit' : 'none'
                }}
            >
                <video style={{ width: "100%", height: "100%", objectFit: "contain" }} src={""}/>
                <canvas className="drawingBuffer"/>
            </div>
            {scanning ?
                <Scanner
                    scannerRef={scannerRef}
                    onDetected={(result: string) => {
                        setScanning(false);
                        setSearchString(result);
                    }}
                    locate={false}
                    area={{ top: "25%", bottom: "25%" }}
                /> : null}
        </div>
    );
};

export default Search;