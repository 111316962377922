import config from "../config";

export interface VersionString {
    version: string;
}
export const fetchVersion = () => {
    const headers: HeadersInit = {};
    headers['Accept'] = 'application/json';

    return fetch(`${window.location.origin}/version.json`, {
        method: 'GET',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers,
    }).then((response: Response) => {
        if (response.ok) {
            return response.json();
        }
        throw response;
    }).then((result) => {
        return result as VersionString;
    }).catch((e)=>{
        console.log(e);
        // Fail somewhat silently
        const version = config.environment.version;
        return {
            version
        } as VersionString;
    });
};
