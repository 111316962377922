import Rental from "../../BusinessObjects/RentalInterfaces";
import TransferredProjectCost from "../../models/TransferredProjectCost";

export enum ActionTypes {
    RENTALS_LOADED = "Profile/Rentals/RENTALS_LOADED",
    RENTALS_EMPTIED = "Profile/Rentals/RENTALS_EMPTIED",
    COSTS_LOADED = "Profile/Rentals/COSTS_LOADED"
}

export interface ProfileAction {
    type: ActionTypes;
    [propName: string]: any;
}

export const rentalFetchSucceeded = (rentals: Rental[]) => {
    return {
        type: ActionTypes.RENTALS_LOADED,
        rentals
    }
};

export const rentalsEmptied = () => {
    return {
        type: ActionTypes.RENTALS_EMPTIED,
    }
};

export const inventoryFetchSucceeded = (costs: TransferredProjectCost[]) => {
    return {
        type: ActionTypes.COSTS_LOADED,
        costs
    }
};
