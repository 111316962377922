import Product from "../../BusinessObjects/Product";
import Inventory from "../../BusinessObjects/Inventory";
import {InventoryTransfer} from "../../BusinessObjects/InventoryTransfer";

export enum ActionTypes {
    PRODUCT_CHOSEN = "Warehouse/Transfer/PRODUCT_CHOSEN",
    TRANSFER_ADDED = "Warehouse/Transfer/TRANSFER_ADDED",
    SOURCE_WAREHOUSE_SELECTED = "Warehouse/Transfer/SOURCE_WAREHOUSE_SELECTED",
    DESTINATION_WAREHOUSE_SELECTED = "Warehouse/Transfer/DESTINATION_WAREHOUSE_SELECTED",
    WAREHOUSES_LOADED = "Warehouse/Transfer/WAREHOUSES_LOADED",
    AMOUNT_CHANGED = "Warehouse/Transfer/AMOUNT_CHANGED",
    TRANSFER_SUCCEEDED = "Warehouse/Transfer/TRANSFER_SUCCEEDED",
    TRANSFER_SELECTED = "Warehouse/Transfer/TRANSFER_SELECTED",
    TRANSFER_CHANGED = "Warehouse/Transfer/TRANSFER_CHANGED",
    TRANSFER_REMOVED = "Warehouse/Transfer/TRANSFER_REMOVED",
    STATE_REPLACED = "Warehouse/Transfer/STATE_REPLACED"
}

export interface TransferAction {
    type: ActionTypes;
    [propName: string]: any;
}

export interface ProductAction extends TransferAction {
    product: Product;
}

export const chooseProduct = (product?: Product | null): TransferAction => {
    return {
        type: ActionTypes.PRODUCT_CHOSEN,
        product
    };
};

export const setInventorySource = (inventory: Inventory | null): TransferAction => {
    return {
        type: ActionTypes.SOURCE_WAREHOUSE_SELECTED,
        inventory
    };
};

export const setInventoryDestination = (inventory: Inventory | null): TransferAction => {
    return {
        type: ActionTypes.DESTINATION_WAREHOUSE_SELECTED,
        inventory
    };
};

export const addToTransfers = (transfer: InventoryTransfer): TransferAction => {
    return {
        type: ActionTypes.TRANSFER_ADDED,
        transfer
    };
};

export const setInventories = (inventories: Inventory[]): TransferAction => {
    return {
        type: ActionTypes.WAREHOUSES_LOADED,
        inventories
    };
};

export const transferSucceeded = (): TransferAction => {
    return {
        type: ActionTypes.TRANSFER_SUCCEEDED
    };
};

export const setInventoryAmount = (amount: number | null): TransferAction => {
    return {
        type: ActionTypes.AMOUNT_CHANGED,
        amount
    };
};

export const editTransfer = (transfer: InventoryTransfer) => {
    return {
        type: ActionTypes.TRANSFER_SELECTED,
        transfer
    };
};

export const updateTransfer = (transferInfo: { current: InventoryTransfer, newData: InventoryTransfer }) => {
    return {
        type: ActionTypes.TRANSFER_CHANGED,
        transferInfo
    };
};

export const removeFromTransfers = (transfer: InventoryTransfer) => {
    return {
        type: ActionTypes.TRANSFER_REMOVED,
        transfer
    };
};
