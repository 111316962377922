import React, {useEffect, useState} from "react";
import Spinner from './Spinner';
import strings from "../strings";
import './CameraPicker.css';


const CameraPicker = () => {
    const [selectOptions, setSelectOptions] = useState(new Array());
    const [mySelectedCamera, setmySelectedCamera] = useState(localStorage.getItem('mySelectedCamera') || ''); 
    const [cameraPickerLoading, setCameraPickerLoading] = useState(false);
    
    const initComponent = async () => {
        setCameraPickerLoading(true);
        await navigator.mediaDevices.getUserMedia({ video: true });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const deviceOptions = devices.map((device) => {
            if (device.deviceId && device.label){
                return  (
                    <option 
                      value={device.deviceId} 
                      key={device.deviceId}>
                      {device.label}
                    </option>
                )
            }
        });
        deviceOptions.unshift(<option
                                value='0'
                                key='0'
                                selected 
                                >
                                  {strings.noDefaultCameraSaved}
                                </option>);
  
        setSelectOptions(deviceOptions);
        setCameraPickerLoading(false);
    }

    useEffect(() =>{
        initComponent();
    }, []);

    const setSelectedCamera = ((value: string) => {
        let alertText = strings.defaultCameraSaved;
        if(value === '0'){
            localStorage.removeItem('mySelectedCamera');
            alertText = strings.defaultCameraRemoved;
        }
        else{
            localStorage.setItem('mySelectedCamera', value);
        }
        setmySelectedCamera(value);
        alert(alertText);
    })

    if(cameraPickerLoading){
        return <Spinner/>
    }
    else{
        return (
            <div className="select-camera">
                <p>{strings.chooseScanningCameraText}</p>
                <label className="select-camera-label" htmlFor="selectMyDefaultCamera">{strings.selectCamera}</label>
                <select
                    name="selectMyDefaultCamera"
                    id="selectMyDefaultCamera"
                    value={mySelectedCamera}
                    onChange={e => setSelectedCamera(e.target.value)}
                >
                    {selectOptions}
                </select>
            </div>
        )
    }
}

export default CameraPicker;

