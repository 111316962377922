import * as React from 'react';
import {connect} from 'react-redux';
import './Spinner.css';
import strings from "../strings";

const Spinner = () => {
    return <div className="loading">{strings.loading}</div>;
};

export default connect()(Spinner);
