import Product from "../../BusinessObjects/Product";
import Inventory from "../../BusinessObjects/Inventory";
import Project from "../../BusinessObjects/Project";
import {ReturnMaterial} from "../../BusinessObjects/ReturnMaterial";
import ContractAdditionType from "../../BusinessObjects/ContractAdditionType";

export enum ActionTypes {
    SOURCE_PROJECT_CHOSEN = "Warehouse/ReturnMaterial/SOURCE_PROJECT_CHOSEN",
    PRODUCT_CHOSEN = "Warehouse/ReturnMaterial/PRODUCT_CHOSEN",
    RETURNMATERIAL_ADDED = "Warehouse/ReturnMaterial/RETURNMATERIAL_ADDED",
    DESTINATION_PROJECT_SELECTED = "Warehouse/ReturnMaterial/DESTINATION_PROJECT_SELECTED",
    DESTINATION_WAREHOUSE_SELECTED = "Warehouse/ReturnMaterial/DESTINATION_WAREHOUSE_SELECTED",
    DESTINATION_TYPE_SELECTED = "Warehouse/ReturnMaterial/DESTINATION_TYPE_SELECTED",
    COST_TYPE_CHOSEN = "Warehouse/ReturnMaterial/COST_TYPE_CHOSEN",
    CONTRACT_ADDITION_TYPE_CHOSEN = "Warehouse/ReturnMaterial/CONTRACT_ADDITION_TYPE_CHOSEN",
    AMOUNT_CHANGED = "Warehouse/ReturnMaterial/AMOUNT_CHANGED",
    RETURNMATERIAL_SUCCEEDED = "Warehouse/ReturnMaterial/RETURNMATERIAL_SUCCEEDED",
    RETURNMATERIAL_SELECTED = "Warehouse/ReturnMaterial/RETURNMATERIAL_SELECTED",
    RETURNMATERIAL_CHANGED = "Warehouse/ReturnMaterial/RETURNMATERIAL_CHANGED",
    RETURNMATERIAL_REMOVED = "Warehouse/ReturnMaterial/RETURNMATERIAL_REMOVED",
    STATE_REPLACED = "Warehouse/ReturnMaterial/STATE_REPLACED",
    CONTRACT_ADDITIONS_CHANGED = "Warehouse/ReturnMaterial/CONTRACT_ADDITIONS_CHANGED"
}

export interface ReturnAction {
    type: ActionTypes;
    [propName: string]: any;
}

export const chooseProduct = (product?: Product | null): ReturnAction => {
    return {
        type: ActionTypes.PRODUCT_CHOSEN,
        product
    };
};

export const chooseCostType = (costType: string): ReturnAction => {
    return {
        type: ActionTypes.COST_TYPE_CHOSEN,
        costType
    };
};

export const setContractAddition = (contractAddition: ContractAdditionType | null): ReturnAction => {
    return {
        type: ActionTypes.CONTRACT_ADDITION_TYPE_CHOSEN,
        contractAddition
    };
};

export const setProjectSource = (sourceProject: Project | null): ReturnAction => {
    return {
        type: ActionTypes.SOURCE_PROJECT_CHOSEN,
        sourceProject
    };
};

export const setDestinationType = (destinationType: string): ReturnAction => {
    return {
        type: ActionTypes.DESTINATION_TYPE_SELECTED,
        destinationType
    };
};

export const setDestinationProject = (destinationProject: Project | null): ReturnAction => {
    return {
        type: ActionTypes.DESTINATION_PROJECT_SELECTED,
        destinationProject
    };
};

export const setDestinationWarehouse = (destinationWarehouse: Inventory | null): ReturnAction => {
    return {
        type: ActionTypes.DESTINATION_WAREHOUSE_SELECTED,
        destinationWarehouse
    };
};

export const addToReturnMaterial = (returnMaterial: ReturnMaterial): ReturnAction => {
    return {
        type: ActionTypes.RETURNMATERIAL_ADDED,
        returnMaterial
    };
};

export const returnSucceeded = (): ReturnAction => {
    return {
        type: ActionTypes.RETURNMATERIAL_SUCCEEDED
    };
};

export const setInventoryAmount = (amount: number | null): ReturnAction => {
    return {
        type: ActionTypes.AMOUNT_CHANGED,
        amount
    };
};

export const editReturnMaterial = (returnMaterial: ReturnMaterial) => {
    return {
        type: ActionTypes.RETURNMATERIAL_SELECTED,
        returnMaterial
    };
};

export const updateReturnMaterial = (returnMaterialInfo: { current: ReturnMaterial, newData: ReturnMaterial }) => {
    return {
        type: ActionTypes.RETURNMATERIAL_CHANGED,
        returnMaterialInfo
    };
};

export const removeFromReturnMaterial = (returnMaterial: ReturnMaterial) => {
    return {
        type: ActionTypes.RETURNMATERIAL_REMOVED,
        returnMaterial
    };
};

export const setContractAdditions = (contractAdditions: ContractAdditionType[]) => {
    return {
        type: ActionTypes.CONTRACT_ADDITIONS_CHANGED,
        contractAdditions
    };
};