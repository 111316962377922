import {NavPage} from "./types";
import strings from "../../strings";
import warehouse from "./warehouse";

import logo from "../../logo-white.svg";

const menuRoot: NavPage = {
    title: strings.pages.root.title,
    description: strings.pages.root.description,
    items: [
        {
            image: logo,
            title: "Warehouse",
            name: "warehouse",
            target: warehouse
        }
    ],
    redirectToSingleChild: true
};

export default menuRoot;
