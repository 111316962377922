import { PurchaseOrder, PurchaseOrderRow } from "../../BusinessObjects/PurchaseOrder";
import Inventory from "../../BusinessObjects/Inventory";

export enum ActionTypes {
    SET_FETCHING = "Warehouse/ReceiveDelivery/SET_FETCHING",
    STATE_REPLACED = "Warehouse/ReceiveDelivery/STATE_REPLACED",
    PURCHASEORDER_CHOSEN = "Warehouse/ReceiveDelivery/PURCHASEORDER_CHOSEN",
    PURCHASEORDER_REMOVED = "Warehouse/ReceiveDelivery/PURCHASEORDER_REMOVED",
    PURCHASEORDER_CONFIRMED = "Warehouse/ReceiveDelivery/PURCHASEORDER_CONFIRMED",
    PURCHASEORDER_ROW_AMOUNT_CHANGED = "Warehouse/ReceiveDelivery/PURCHASEORDER_ROW_AMOUNT_CHANGED",
    PURCHASEORDER_ROW_EDITED = "Warehouse/ReceiveDelivery/PURCHASEORDER_ROW_EDITED",
    PURCHASEORDER_UPDATED = "Warehouse/ReceiveDelivery/PURCHASEORDER_UPDATED",
    DESTINATION_WAREHOUSE_CHOSEN = "Warehouse/ReceiveDelivery/DESTINATION_WAREHOUSE_CHOSEN",
    PURCHASEORDER_BACKORDER_SET = "Warehouse/ReceiveDelivery/PURCHASEORDER_BACKORDER_SET",
    CONFIRMATION_SUCCEEDED = "Warehouse/ReceiveDelivery/CONFIRMATION_SUCCEEDED",
}

export interface ReceiveDeliveryAction {
    type: ActionTypes;
    [propName: string]: any;
}

export const choosePurchaseOrder = (purchaseOrder?: PurchaseOrder | null) => {
    return {
        type: ActionTypes.PURCHASEORDER_CHOSEN,
        purchaseOrder
    };
};

export const removePurchaseOrder = () => {
    return {
        type: ActionTypes.PURCHASEORDER_REMOVED
    };
};

export const setFetching = (fetching: boolean) => {
    return {
        type: ActionTypes.SET_FETCHING,
        fetching
    };
};

export const editPurchaseOrderRow = (purchaseOrderRow: PurchaseOrderRow) => {
    return {
        type: ActionTypes.PURCHASEORDER_ROW_EDITED,
        purchaseOrderRow
    };
};

export const changePurchaseOrderRowAmount = (amount: number | null) => {
    return {
        type: ActionTypes.PURCHASEORDER_ROW_AMOUNT_CHANGED,
        amount
    };
}

export const updatePurchaseOrder = () => {
    return {
        type: ActionTypes.PURCHASEORDER_UPDATED
    };
};

export const confirmPurchaseOrder = () => {
    return {
        type: ActionTypes.PURCHASEORDER_CONFIRMED
    };
};

export const chooseDestinationWarehouse = (destinationWarehouse?: Inventory | null): ReceiveDeliveryAction => {
    return {
        type: ActionTypes.DESTINATION_WAREHOUSE_CHOSEN,
        destinationWarehouse
    };
};

export const setBackorder = (backOrder: boolean) => {
    return  {
        type: ActionTypes.PURCHASEORDER_BACKORDER_SET,
        backOrder
    }
}

export const confirmationSucceeded = (): ReceiveDeliveryAction => {
    return {
        type: ActionTypes.CONFIRMATION_SUCCEEDED
    };
};
