import React, {useState} from "react";
import {ThunkDispatch} from "redux-thunk";
import AppState from "../store/appstate";
import {connect} from "react-redux";
import strings from "../strings";
import ToggleButton from '@material-ui/lab/ToggleButton';
import {PopperProps, TextField, Button} from "@material-ui/core";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import ContractAdditionType from "../BusinessObjects/ContractAdditionType";
import { setContractAdditions } from "../actions/Warehouse/RegisterMaterialActions";
import "./CostTypeChoice.css"
import AutocompletePopper from "./AutocompletePopper";
import {toggleButtonStyles} from "../styles/toggleButtonStyles";
import Project from "../BusinessObjects/Project";
import Spinner from "./Spinner";
import Api from "../utils/api";
import AddCostTypeChoice from "./AddCostTypeChoice";
import Alerts from "./Alerts";

export enum CostTypes {
    contract = "contract",
    addition = "addition"
}

const defaultChoice = CostTypes.contract;
const emptyType = {name: ''} as ContractAdditionType;

interface OwnProps {
    chooseCostType: (costType: string) => void;
    setContractAddition: (contractAddition: ContractAdditionType | null) => void;
    costType?: string;
    contractAddition: ContractAdditionType | null;
    currentProject: Project | null;
}
interface StateProps {
    contractAdditions?: ContractAdditionType[] | [];
}
interface DispatchProps{
    setContractAdditions: (contractAdditions: ContractAdditionType[]) => void;
}
const CostTypeChoice = (props: OwnProps & StateProps & DispatchProps) => {

    const classes = toggleButtonStyles();
    const costType = props.costType ?? defaultChoice;
    const currentProject = props.currentProject;
    const [addingTypeChoice, setAddingTypeChoice] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const apiUrl = '/v1/projects/contractAdditionTypes';

    const handleClickClose = () => {
        setOpenModal(false);
    }

    const addType = async (name: string) => {
        setOpenModal(false);
        setAddingTypeChoice(true);
        const tempContractAdditions = props.contractAdditions?? [];
        const data = {
            projectId: currentProject?.id,
            name,
        }
        if (currentProject && name){
            try {
                const newItem = await Api.call(apiUrl, 'POST', data);
                tempContractAdditions.splice(-1, 0, newItem)
                props.setContractAddition(tempContractAdditions[tempContractAdditions.length-2]);
                props.setContractAdditions(tempContractAdditions);
            }
            catch (error){
                ineum('reportError', error);
                Alerts.error(strings.errorSavingContractAdditionType, () => {return true;});
            }
        } 
        setAddingTypeChoice(false);
    }

    const contractAdditionLink = <Button    variant="text"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                if(!currentProject) Alerts.error(strings.chooseProjectFirst, () => {return true;});
                                                else setOpenModal(true);
                                            }}>{strings.SaveNewContractAdditionType}</Button>;

    const linkOption: ContractAdditionType = {name: '', id: -1};
    const tempContractAdditions: any[] = props.contractAdditions?? [];
    if (tempContractAdditions[tempContractAdditions.length - 1]?.id !== -1) tempContractAdditions.push(linkOption);

    const filter = createFilterOptions({
        stringify: (option: ContractAdditionType) => option.name,
    });

    const additionTypeDropDown = (props.costType === CostTypes.addition) ?
    <>
        <Autocomplete
            fullWidth={true}
            renderInput={(params) => <TextField {...params}
                                                label={strings.contractAdditionType}
                                                InputProps={{...params.InputProps,
                                                disableUnderline: true
                                                        }}/>}
            options={tempContractAdditions ? tempContractAdditions: []}
            renderOption={(option) => option.id !== -1 ? option.name : contractAdditionLink}
            getOptionLabel={(option) => option.name}
            PopperComponent={(popperProps: PopperProps) => <AutocompletePopper {...popperProps}
                                                               className={popperProps.className + " contract-addition"}
                                                               children={popperProps.children}/>}
            onChange={(event, option: ContractAdditionType | null) => {
                if(option?.id !== -1) props.setContractAddition(option);
            }}
            noOptionsText={strings.noContractAdditionTypesFound}
            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (params.inputValue !== '') filtered.push(linkOption);
                                return filtered;
                                }
                            }
            value={props.contractAddition?.name !== '' ? props.contractAddition : ''}
            getOptionSelected={(option, value) => option.id === value.id && option.name === value.name}
        />
        <AddCostTypeChoice open={openModal} handleClose={handleClickClose} addType={addType} />
        </>
        : null;

    return (
        addingTypeChoice? <Spinner></Spinner>:
        <div>
            <div className={classes.toggleButtonContainer}>
                <ToggleButton value={CostTypes.contract} className={classes.toggleButton} selected={costType === CostTypes.contract}
                              onClick={() => {
                                  props.chooseCostType(CostTypes.contract)
                              }}>
                    {strings.contract}
                </ToggleButton>
                <ToggleButton value={CostTypes.addition} className={classes.toggleButton} selected={costType === CostTypes.addition}
                              onClick={() => {
                                  props.chooseCostType(CostTypes.addition)
                              }}>
                    {strings.addition}
                </ToggleButton>
            </div>
            {additionTypeDropDown}
        </div>

    );
};
const mapStateToProps = (state: AppState) => {
    return {
        contractAdditions: state.warehouse.registerMaterial.contractAdditions
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
    return {setContractAdditions: (contractAdditions: ContractAdditionType[]) => dispatch(setContractAdditions(contractAdditions))};

}

export default connect(mapStateToProps, mapDispatchToProps)(CostTypeChoice);
