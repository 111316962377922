import Project from "../../BusinessObjects/Project";
import Rental, { WarehouseInfoShelf } from "../../BusinessObjects/RentalInterfaces";
import { DestinationType } from "../../components/RentEquipmentDestination";

export enum ActionTypes {
    RENTAL_CHOSEN = "Warehouse/RentEquipment/PRODUCT_CHOSEN",
    DESTINATION_TYPE_CHOSEN = "Warehouse/RentEquipment/DESTINATION_TYPE_CHOSEN",
    DESTINATION_PROJECT_CHOSEN = "Warehouse/RentEquipment/DESTINATION_PROJECT_CHOSEN",
    DESTINATION_WAREHOUSE_CHOSEN = "Warehouse/RentEquipment/DESTINATION_WAREHOUSE_CHOSEN",
    EQUIPMENT_RENTED = "Warehouse/RentEquipment/RENTAL_RENTED",
    STATE_REPLACED = "Warehouse/RentEquipment/STATE_REPLACED"
}

export interface RentEquipmentAction {
    type: ActionTypes;
    [propName: string]: any;
}

export const chooseRental = (rental?: Rental | null): RentEquipmentAction => {
    return {
        type: ActionTypes.RENTAL_CHOSEN,
        rental
    };
};

export const setDestinationType = (destinationType: DestinationType | null): RentEquipmentAction => {
    return {
        type: ActionTypes.DESTINATION_TYPE_CHOSEN,
        destinationType
    };
};

export const setDestinationProject = (destinationProject: Project | null): RentEquipmentAction => {
    return {
        type: ActionTypes.DESTINATION_PROJECT_CHOSEN,
        destinationProject
    };
};

export const setDestinationWarehouse = (destinationWarehouse: WarehouseInfoShelf | null): RentEquipmentAction => {
    return {
        type: ActionTypes.DESTINATION_WAREHOUSE_CHOSEN,
        destinationWarehouse
    };
};

export const rentEquipment = (): RentEquipmentAction => {
    return {
        type: ActionTypes.EQUIPMENT_RENTED
    };
};
