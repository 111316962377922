import { unmountComponentAtNode } from "react-dom";
import { confirmAlert } from "react-confirm-alert";
import "./Alerts.css";
import strings from "../strings";

function alertsCallback(handler?: CallableFunction) {
    const func = handler;

    function callback() {
        if (!func) {
            return;
        }

        func();
    }

    return {
        callback
    };
}

function closeAlert() {
    document.body.classList.remove('react-confirm-alert-body-element');

    const target = document.getElementById('react-confirm-alert');
    if (target) {
        unmountComponentAtNode(target);
        target.parentNode?.removeChild(target);
    }

    const svg = document.getElementById('react-confirm-alert-firm-svg');
    svg?.parentNode?.removeChild(svg);
    document.body.children[0].classList.remove('react-confirm-alert-blur');
}

const alerts = (function () {
    function error(message: string, func: CallableFunction) {
        confirmAlert({
            closeOnClickOutside: false,
            title: strings.errorTitle,
            message,
            buttons: [
                {
                    label: strings.ok,
                    onClick: alertsCallback(func).callback
                }
            ]
        });

        window.onpopstate = () => {
            closeAlert();
        }
    }

    return {
        error
    };
})();

export default alerts;
