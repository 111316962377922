import { createUserManager } from 'redux-oidc';
import config from "../config";

const userManagerConfig = {
    client_id: 'sundstroms-mobile',
    popup_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
    response_type: 'code',
    scope: 'openid profile roles sundstroms-middleware lemonsoft marto',
    authority: config.identityServer.authority,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

userManager.events.addSilentRenewError(() => {
    userManager.signoutRedirect();
});

export default userManager;
