import Box from "@material-ui/core/Box";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {Dispatch} from "redux";
import {showMessage} from "../actions/UIActions";
import {connect} from "react-redux";
import config from "../config";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Icon from "@material-ui/core/Icon";

export type MessageType = 'success' | 'error' | undefined

interface OwnProps {
    message: string;
    type: MessageType;
}

interface DispatchProps {
    reset: () => void;
}

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: "flex",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            minHeight: "4rem",
            backgroundColor: "#efefef",
            alignItems: "center",
            padding: "0 0.5rem",
        },
        text: {
            marginLeft: "0.5rem"
        }
    }
});

const MessageBar = (props: OwnProps & DispatchProps) => {
    const classes = useStyles();
    const icon =
        <Icon
            className={props.type === 'success' ? "far fa-check-circle" : "far fa-times-circle"}
            style={props.type === 'success' ? {color:"green"} : {color:"red"}}
        />;
    const ms = props.type === 'success' ? config.ui.timeouts.messageTimeout : config.ui.timeouts.messageTimeout + 5000;
    setTimeout(() => props.reset(), ms);
    const borderColor=props.type==="success" ? "green" : "red";
    return (
        <Box className={classes.root} borderTop={`3px solid ${borderColor}`}>
            {icon}
            <Typography className={classes.text}>{props.message}</Typography>
        </Box>
    )
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reset: () => dispatch(showMessage())
    }
};

export default connect(null, mapDispatchToProps)(MessageBar);
