import {NavRouteItem} from "../config/navigation/helpers";
import {history} from "../store";

export function navigateTo(target: NavRouteItem, replace = false): void {
    if (replace) {
        history.replace(target.path);
    } else {
        history.push(target.path);
    }
}
