import TransferState from "./TransferState";
import {ActionTypes, ProductAction, TransferAction} from "../../actions/Warehouse/TransferActions";
import initialBaseState from "./initialState";

const initialState: TransferState = {
    ...initialBaseState,
    transferSource: null,
    transferDestination: null,
    transfers: []
};

const TransferReducer = (state: TransferState = initialState, action: TransferAction) => {
    switch (action.type) {
        case ActionTypes.PRODUCT_CHOSEN:
            return (
                action.product !== state.currentProduct
                && (action.product && action.product.id !== state.currentProduct?.id))
                ?
                {
                    ...state,
                    currentProduct: (action as ProductAction).product,
                    transferSource: null,
                    transferDestination: null
                }
                : state;
        case ActionTypes.SOURCE_WAREHOUSE_SELECTED:
            return {
                ...state,
                transferSource: action.inventory
            };
        case ActionTypes.DESTINATION_WAREHOUSE_SELECTED:
            return {
                ...state,
                transferDestination: action.inventory
            };
        case ActionTypes.TRANSFER_ADDED:
            return {
                ...state,
                transfers: [...state.transfers, action.transfer],
                transferSource: null,
                transferDestination: null,
                currentProduct: null,
                amount: 1
            };
        case ActionTypes.TRANSFER_SUCCEEDED:
            return {
                ...state,
                transfers: []
            };
        case ActionTypes.WAREHOUSES_LOADED:
            return {
                ...state,
                inventories: action.inventories
            };
        case ActionTypes.AMOUNT_CHANGED:
            return {
                ...state,
                amount: action.amount
            };
        case ActionTypes.TRANSFER_SELECTED:
            return {
                ...state,
                currentProduct: action.transfer.product,
                currentTransfer: action.transfer,
                transferSource: action.transfer.sourceWarehouse,
                transferDestination: action.transfer.destinationWarehouse,
                amount: action.transfer.amount
            };
        case ActionTypes.TRANSFER_CHANGED:
            return {
                ...state,
                transfers: state.transfers.map(
                    (item) => item === action.transferInfo.current
                        ? action.transferInfo.newData
                        : item),
                transferSource: null,
                transferDestination: null,
                currentProduct: null,
                currentTransfer: null,
                amount: 1
            };

        case ActionTypes.TRANSFER_REMOVED:
            return {
                ...state,
                transfers: state.transfers.filter(
                    (item) => item !== action.transfer),
                transferSource: null,
                transferDestination: null,
                currentProduct: null,
                currentTransfer: null,
                amount: 1
            };
        case ActionTypes.STATE_REPLACED:
            return action.newState ?? state;
        default:
            return state;
    }
};

export default TransferReducer;