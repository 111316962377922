import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import * as themeAugmentation from '@material-ui/lab/themeAugmentation';
import AutocompletePopper from "../components/AutocompletePopper";
import config from "../config";

const mainTheme = createMuiTheme({
    palette: {
        primary: {
            main: config.ui.mainColor === "" ? '#102694' : config.ui.mainColor,
            light: "#544fc6",
            dark: "#000165",
            contrastText: "white"
        }
    },
    props: {
        MuiAutocomplete: {
            PopperComponent: AutocompletePopper
        }
    },
    overrides: {
        MuiButton: {
            root: {
                display: "block",
                padding: "20px",
                borderRadius: 0,
                margin: "10px 0",
                '&$disabled': {
                    color: "white !important",
                    backgroundColor: '#c2c7e4 !important',
                    '&:hover': {
                        backgroundColor: '#c2c7e4 !important',
                        boxShadow: "none"
                    }
                }
            },
            contained: {
                boxShadow: "none",
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#f0f0f0",
                color: "black",
            },
            root: {
                boxShadow: "none"
            }
        },
        MuiIconButton: {
            root: {
                color: "inherit",
                fontSize: "34px",
                flex: "revert"
            }
        },
        MuiFormControl: {
            root: {
                border: "solid 2px black",
                margin: "0.3em 0 0.3em",
                boxSizing: "border-box",
                padding: "12px 0"
            },
        },
        MuiInputLabel: {
            root: {
                marginLeft: "10px",
                fontWeight: "bold"
            },
            shrink: {
                color: "inherit",
                marginTop: "0.5em"
            }
        },
        MuiInput: {
            root: {
                paddingLeft: "10px",
            },
            underline: {
                '&:before': {
                    borderBottom: "none"
                },
                '&:after': {
                    borderBottom: "none"
                },
            },
        },
        MuiInputAdornment: {
            root: {
                transform: "translateY(-0.5em)"
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                paddingRight: "0 !important"
            },
            endAdornment: {
                display: "flex",
                flexDirection: "row",
                transform: "translateY(-0.5em)"
            },
            popper: {
                marginTop: "12px"
            },
            paper: {
                margin: "-2px",
                boxShadow: "revert",
                borderRadius: 0,
                border: "2px solid black",
                "&:empty": {
                    border: 0
                }
            },
            listbox: {
                padding: 0
            },
            option: {
                minHeight: "revert",
                paddingTop: "10px",
                paddingRight: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                margin: 0
            }
        },
        MuiLink: {
            root: {
                textDecorationLine: "underline"
            },
            underlineHover: {
                textDecorationLine: "underline"
            }
        },
        MuiToggleButton: {
            root: {
                textTransform: "none",
                fontWeight: "normal",
                color: "white",
                backgroundColor: "#c2c7e4",
                margin: "10px 0",
                '&$selected': {
                    color: "white",
                    backgroundColor: '#102694',
                    '&:hover': {
                        backgroundColor: '#102694'
                    },
                }
            }
        }
    },
    typography: {
        button: {
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "1em",
            color: "white"
        }
    }
});

export default mainTheme;
