import {User} from "oidc-client";

export const Policies = {
    unauthenticated: (user: User | null) => {
        return user === null;
    },
    authenticated: (user: User | null) => {
        return user !== null;
    },
    lemonsoftUser: (user: User | null) => {
        if (Policies.unauthenticated(user)) {
            return false;
        }

        let result = false;
        if (user?.profile.hasOwnProperty('role')) {
            if (Array.isArray(user?.profile.role)) {
                result = user?.profile.role?.includes('Inventory');
            } else {
                result = user?.profile.role === 'Inventory';
            }
        }
        return result;
    },
    lemonsoftReceiverUser: (user: User | null) => {
        if (Policies.unauthenticated(user)) {
            return false;
        }

        let result = false;
        if (user?.profile.hasOwnProperty('role')) {
            if (Array.isArray(user?.profile.role)) {
                result = user?.profile.role?.includes('Inventory') && user?.profile.role?.includes('OrderReceiver');
            }
        }
        return result;
    },
    lemonsoftRentingMasterUser: (user: User | null) => {
        if (Policies.unauthenticated(user)) {
            return false;
        }

        let result = false;
        if (user?.profile.hasOwnProperty('role')) {
            if (Array.isArray(user?.profile.role)) {
                result = user?.profile.role?.includes('Inventory') && user?.profile.role?.includes('OrderMasterLender');
            }
        }
        return result;
    },
    lunchOrderingUser: (user: User | null) => {
        if (Policies.unauthenticated(user)) {
            return false;
        }

        let result = false;
        if (user?.profile.hasOwnProperty('role')) {
            if (Array.isArray(user?.profile.role)) {
                result = user?.profile.role?.includes('Lunch');
            }
        }
        return result;
    }
};
