import ProfileState from "./ProfileState";
import { ActionTypes, ProfileAction } from "../../actions/Profile/ProfileActions";

const initialState = {
    rentals: [],
    costs:[]
};


const ProfileReducer = (state: ProfileState = initialState, action: ProfileAction) => {
    switch (action.type) {
        case ActionTypes.RENTALS_LOADED:
            return {
                ...state,
                rentals: action.rentals
            };
        case ActionTypes.RENTALS_EMPTIED:
            return {
                ...state,
                rentals: []
            };
        case ActionTypes.COSTS_LOADED:
            return {
                ...state,
                costs: action.costs
            };
        default:
            return state;
    }
};

export default ProfileReducer;
