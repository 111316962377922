import Product from "../../BusinessObjects/Product";
import Inventory from "../../BusinessObjects/Inventory";
import Project from "../../BusinessObjects/Project";
import {MaterialRegistration} from "../../BusinessObjects/MaterialRegistration";
import ContractAdditionType from "../../BusinessObjects/ContractAdditionType";

export enum ActionTypes {
    PRODUCT_CHOSEN = "Warehouse/RegisterMaterial/PRODUCT_CHOSEN",
    SEARCH_RESULT_UPDATED = "Warehouse/RegisterMaterial/SEARCH_RESULT_UPDATED",
    SOURCE_WAREHOUSE_SELECTED = "Warehouse/RegisterMaterial/SOURCE_WAREHOUSE_SELECTED",
    AMOUNT_CHANGED = "Warehouse/RegisterMaterial/AMOUNT_CHANGED",
    PROJECT_CHOSEN = "Warehouse/RegisterMaterial/PROJECT_CHOSEN",
    REGISTRATION_ADDED = "Warehouse/RegisterMaterial/REGISTRATION_ADDED",
    REGISTRATION_SUCCEEDED = "Warehouse/RegisterMaterial/REGISTRATION_SUCCEEDED",
    REGISTRATION_SELECTED = "Warehouse/RegisterMaterial/REGISTRATION_SELECTED",
    REGISTRATION_CHANGED = "Warehouse/RegisterMaterial/REGISTRATION_CHANGED",
    REGISTRATION_REMOVED = "Warehouse/RegisterMaterial/REGISTRATION_REMOVED",
    COST_TYPE_CHOSEN = "Warehouse/RegisterMaterial/COST_TYPE_CHOSEN",
    CONTRACT_ADDITION_TYPE_CHOSEN = "Warehouse/RegisterMaterial/CONTRACT_ADDITION_TYPE_CHOSEN",
    STATE_REPLACED = "Warehouse/RegisterMaterial/STATE_REPLACED",
    CONTRACT_ADDITIONS_CHANGED = "Warehouse/RegisterMaterial/CONTRACT_ADDITIONS_CHANGED"
}

export interface RegisterMaterialAction {
    type: ActionTypes;
    [propName: string]: any;
}

export interface ProductAction extends RegisterMaterialAction {
    product: Product;
}

export const chooseProduct = (product?: Product | null): RegisterMaterialAction => {
    return {
        type: ActionTypes.PRODUCT_CHOSEN,
        product
    };
};

export const setSearchResults = (result: Product[]): RegisterMaterialAction => {
    return {
        type: ActionTypes.SEARCH_RESULT_UPDATED,
        result
    };
};

export const setInventorySource = (inventory: Inventory | null): RegisterMaterialAction => {
    return {
        type: ActionTypes.SOURCE_WAREHOUSE_SELECTED,
        inventory
    };
};

export const setInventoryAmount = (amount: number | null): RegisterMaterialAction => {
    return {
        type: ActionTypes.AMOUNT_CHANGED,
        amount
    };
};

export interface ProjectAction extends RegisterMaterialAction {
    project?: Project | null;
}

export const chooseProject = (project?: Project | null): ProjectAction => {
    return {
        type: ActionTypes.PROJECT_CHOSEN,
        project
    };
};

export const chooseCostType = (costType: string): RegisterMaterialAction => {
    return {
        type: ActionTypes.COST_TYPE_CHOSEN,
        costType
    };
};

export const addRegistration = (materialRegistration: MaterialRegistration): RegisterMaterialAction => {
    return {
        type: ActionTypes.REGISTRATION_ADDED,
        materialRegistration
    };
};

export const setContractAddition = (contractAddition: ContractAdditionType | null): RegisterMaterialAction => {
    return {
        type: ActionTypes.CONTRACT_ADDITION_TYPE_CHOSEN,
        contractAddition
    };
};

export const registrationSucceeded = (): RegisterMaterialAction => {
    return {
        type: ActionTypes.REGISTRATION_SUCCEEDED
    };
};

export const editRegistration = (registrationInfo: MaterialRegistration) => {
    return {
        type: ActionTypes.REGISTRATION_SELECTED,
        registrationInfo
    };
};

export const updateRegistration = (current: MaterialRegistration | null, newData: MaterialRegistration) => {
    return {
        type: ActionTypes.REGISTRATION_CHANGED,
        current,
        newData
    };
};

export const removeRegistration = (registrationInfo: MaterialRegistration | null) => {
    return {
        type: ActionTypes.REGISTRATION_REMOVED,
        registrationInfo
    };
};

export const setContractAdditions = (contractAdditions: ContractAdditionType[]): RegisterMaterialAction => {
    return {
        type: ActionTypes.CONTRACT_ADDITIONS_CHANGED,
        contractAdditions
    };
};
