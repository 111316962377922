import ReceiveDeliveryState from "./ReceiveDeliveryState";
import {ActionTypes, ReceiveDeliveryAction} from "../../actions/Warehouse/ReceiveDeliveryActions";

const initialState: ReceiveDeliveryState = {
    destinationWarehouse: null,
    currentPurchaseOrder: null,
    currentPurchaseOrderRow: null,
    amount: 0,
    backOrder: false,
    fetching: false
}

const ReceiveDeliveryReducer = (state: ReceiveDeliveryState = initialState, action: ReceiveDeliveryAction) => {
    switch(action.type) {
        case ActionTypes.PURCHASEORDER_CHOSEN:
            return {
                ...state,
                currentPurchaseOrder: action.purchaseOrder
            };
        case ActionTypes.PURCHASEORDER_REMOVED:
            return {
                state: initialState
            };
        case ActionTypes.SET_FETCHING:
            return {
                ...state,
                fetching: action.fetching
            };
        case ActionTypes.PURCHASEORDER_ROW_EDITED:
            return {
                ...state,
                currentPurchaseOrderRow: action.purchaseOrderRow
            };
        case ActionTypes.PURCHASEORDER_ROW_AMOUNT_CHANGED:
            return {
                ...state,
                amount: action.amount
            };
        case ActionTypes.PURCHASEORDER_UPDATED:
            if(state.currentPurchaseOrderRow) {
                state.currentPurchaseOrderRow.deliveredAmount = state.amount;
                state.currentPurchaseOrderRow.backorder = state.backOrder;
            }
            return {
                ...state,
                currentPurchaseOrderRow: null,
                amount: 0,
                backOrder: false
            };
        case ActionTypes.PURCHASEORDER_CONFIRMED:
            return {
                state: initialState
            };
        case ActionTypes.DESTINATION_WAREHOUSE_CHOSEN:
            return {
                ...state,
                destinationWarehouse: action.destinationWarehouse
            };
        case ActionTypes.PURCHASEORDER_BACKORDER_SET:
            return {
                ...state,
                backOrder: action.backOrder
            };
        case ActionTypes.CONFIRMATION_SUCCEEDED:
            return {
                state: initialState
            };
        case ActionTypes.STATE_REPLACED:
            return action.newState ?? state;
        default:
            return state;
    }
};

export default ReceiveDeliveryReducer;
