import ReturnMaterialState from "./ReturnMaterialState";
import {ActionTypes, ReturnAction} from "../../actions/Warehouse/ReturnMaterialActions";
import {ReturnDestinationType} from "../../components/ReturnDestinationChoice";
import {CostTypes} from "../../components/CostTypeChoice";

const initialState: ReturnMaterialState = {
    sourceProject: null,
    currentProduct: null,
    currentReturnMaterial: null,
    destinationType: ReturnDestinationType.inventory,
    destinationWarehouse: null,
    destinationProject: null,
    costType: CostTypes.contract,
    contractAddition: null,
    amount: 1,
    material: []
};

const ReturnMaterialReducer = (state: ReturnMaterialState = initialState, action: ReturnAction) => {
    switch (action.type) {
        case ActionTypes.PRODUCT_CHOSEN:
            return (
                action.product !== state.currentProduct
                && (action.product && action.product.id !== state.currentProduct?.id))
                ?
                {
                    ...state,
                    currentProduct: action.product,
                }
                : state;
        case ActionTypes.SOURCE_PROJECT_CHOSEN:
            return {
                ...state,
                sourceProject: action.sourceProject
            };
        case ActionTypes.DESTINATION_TYPE_SELECTED:
            return {
                ...state,
                destinationProject: null,
                destinationWarehouse: null,
                destinationType: action.destinationType
            };
        case ActionTypes.COST_TYPE_CHOSEN:
            return {
                ...state,
                costType: action.costType
            };
        case ActionTypes.CONTRACT_ADDITION_TYPE_CHOSEN :
            return {
                ...state,
                contractAddition: action.contractAddition
            };
        case ActionTypes.DESTINATION_WAREHOUSE_SELECTED:
            return {
                ...state,
                destinationWarehouse: action.destinationWarehouse,
                destinationProject: null,
            };
        case ActionTypes.DESTINATION_PROJECT_SELECTED:
            return {
                ...state,
                destinationWarehouse: null,
                destinationProject: action.destinationProject
            };
        case ActionTypes.RETURNMATERIAL_ADDED:
            return {
                ...state,
                material: [...state.material, action.returnMaterial],
                currentProduct: null,
                amount: 1
            };
        case ActionTypes.RETURNMATERIAL_SUCCEEDED:
            return {
                state: initialState,
                material: []
            };
        case ActionTypes.AMOUNT_CHANGED:
            return {
                ...state,
                amount: action.amount
            };
        case ActionTypes.RETURNMATERIAL_SELECTED:
            return {
                ...state,
                currentReturnMaterial: action.returnMaterial,
                currentProduct: action.returnMaterial.product,
                sourceProject: action.returnMaterial.sourceProject,
                destinationWarehouse: action.returnMaterial.destinationWarehouse,
                destinationProject: action.returnMaterial.destinationProject,
                amount: action.returnMaterial.amount
            };
        case ActionTypes.RETURNMATERIAL_CHANGED:
            return {
                ...state,
                material: state.material.map(
                    (item) => item === action.returnMaterialInfo.current
                        ? action.returnMaterialInfo.newData
                        : item),
                currentProduct: null,
                currentReturnMaterial: null,
                amount: 1
            };

        case ActionTypes.RETURNMATERIAL_REMOVED:
            return {
                ...state,
                material: state.material.filter(
                    (item) => item !== action.returnMaterial),
                currentProduct: null,
                currentReturnMaterial: null,
                amount: 1
            };
        case ActionTypes.STATE_REPLACED:
            return action.newState ?? state;
        case ActionTypes.CONTRACT_ADDITIONS_CHANGED:
            return {
                ...state,
                contractAdditions: action.contractAdditions
            };
        default:
            return state;
    }
};

export default ReturnMaterialReducer;
