import strings from "../strings";

export enum ProjectTypes {
    ASPHALT_CONTRACT = 1,
    SOILBUILDING = 2,
    TILE_DRAINAGE = 3,
    STORAGE = 4,
    CONCRETE = 6,
    CRUSHING = 7,
    RAILROAD_WORK = 8,
    CATCHMENT = 9,
    PIPES_AND_FUEL = 10,
    SERVICE = 94,
    ASPHALT_HOURLY = 95,
    ASPHALT_TRANSPORT = 96,
    TRANSPORTATION = 97,
    CONCRETE_PUMPING = 98,
    COMMON = 99,
    OTHER = 100,
}

interface ProjectType {
    type: ProjectTypes,
    names: {
        typeTexts: {
            sv: string,
            en: string,
            fi: string
        }
    }
}

// I would really like to fetch these from Marto via API call, but we need
// to have a discussion about whether that fetch really would have to go through the middleware.

const DefinedProjectTypes = [
    {
        type: ProjectTypes.ASPHALT_CONTRACT,
        names: {
            typeTexts: {
                sv: "Asfalt",
                en: "Asphalt",
                fi: "Asfaltti"
            }
        }
    },
    {
        type: ProjectTypes.SOILBUILDING,
        names: {
            typeTexts: {
                sv: "Jordbyggnad",
                en: "Earthwork",
                fi: "Maanrakennus",
            }
        }
    },
    {
        type: ProjectTypes.TILE_DRAINAGE,
        names: {
            typeTexts: {
                sv: "Täckdikning",
                en: "Tile Drainage",
                fi: "Salaojitus"
            }
        }
    },
    {
        type: ProjectTypes.STORAGE,
        names: {
            typeTexts: {
                sv: "Lager",
                en: "Storage",
                fi: "Varasto"
            }
        }
    },
    {
        type: ProjectTypes.RAILROAD_WORK,
        names: {
            typeTexts: {
                sv: "Järnväg",
                en: "Railroad",
                fi: "Rautatie"
            }
        }
    }
];

export const getProjectTypeText = (pt: ProjectTypes) => {
    const projectType = DefinedProjectTypes.find((x: ProjectType) => x.type === pt);
    try {
        // @ts-ignore
        return projectType.names.typeTexts[(strings.getLanguage())];
    } catch (e) {
        return "";
    }
};

