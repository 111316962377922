import NavigationState from "./navigationstate";
import {
    NavigationAction,
    NavigationActionType,
    NavigationMenuUpdateAction,
    NavigationRootUpdateAction,
    NavigationUpdateAction
} from "../actions/NavigationActions";

const initialState: NavigationState = {
    currentRoute: null,
    rootRoute: null,
    menuRoutes: []
};

function arraysAreIdentical<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }

    return array1.every((item1: T, i: number) => item1 === array2[i]);
}

const navigationReducer = (state: NavigationState = initialState, action: NavigationAction) => {
    switch (action.type) {
        case NavigationActionType.NAVIGATION_ROOT_LOADED:
            return {
                ...state,
                rootRoute: (action as NavigationRootUpdateAction).payload
            };
        case NavigationActionType.NAVIGATION_UPDATED:
            const newRoute = (action as NavigationUpdateAction).payload;
            if (state.currentRoute === newRoute) {
                return state;
            }

            return {
                ...state,
                currentRoute: newRoute
            };
        case NavigationActionType.NAVIGATION_MENU_UPDATED:
            const menu = (action as NavigationMenuUpdateAction).payload
            if (arraysAreIdentical(state.menuRoutes, menu)) {
                return state;
            }

            return {
                ...state,
                menuRoutes: menu
            };
        default:
            return state;
    }
};

export default navigationReducer;
