import React from "react";
import {NavItem, NavPage, NavTarget} from "./types";
import {User} from "oidc-client";
import {RouteComponentProps} from "react-router";
import NavPageMenu from "../../components/NavPageMenu";
import PageRenderer from "../../components/PageRenderer";

export interface NavRouteItem {
    path: string;
    render: (props: RouteComponentProps<any>) => any;
    navPage: NavPage;
    navItem?: NavItem;
    parent?: NavRouteItem;
    children: NavRouteItem[];
    redirectToSingleChild?: boolean;
}

export function isNavPage(item: NavTarget): item is NavPage {
    return !!(item as NavPage).title;
}

export function userHasAccessToItem(user: User | null, item: NavItem): boolean {
    if (item.authorization === undefined) {
        if (isNavPage(item.target)) {
            return userHasAccessToPage(user, item.target);
        }

        return true;
    }

    return item.authorization(user);
}

export function userHasAccessToPage(user: User | null, page: NavPage): boolean {
    if (page.items.length === 0) {
        return true;
    }

    return page.items.some(item => {
        return userHasAccessToItem(user, item);
    });
}

export function getRenderComponentForItem(item: NavTarget): any {
    if ("render" in item) {
        const RenderComponent = item as React.ComponentType<any>;
        return <RenderComponent/>;
    } else {
        return item;
    }
}

function getPageRenderer(menuTitle: string, current: NavRouteItem, parent: NavRouteItem, target: NavTarget): () => React.ReactElement {
    return () => <PageRenderer menuTitle={menuTitle} parent={parent} current={current}
                               render={() => getRenderComponentForItem(target)}/>;
}

function pathPartsToUrl(pathParts: string[]): string {
    return "/" + pathParts.join("/");
}

export function getNavRouteForNavPage(user: User, page: NavPage, pathParts: string[] = [], parent?: NavRouteItem): NavRouteItem | null {
    if (!userHasAccessToPage(user, page)) {
        return null;
    }

    const items = page.items.filter(item => {
        return userHasAccessToItem(user, item);
    });

    const pageRouteItem: NavRouteItem = {
        path: pathPartsToUrl(pathParts),
        navPage: page,
        parent,
        render: () => <></>, // Set below
        children: [],
        redirectToSingleChild: page.redirectToSingleChild
    };
    pageRouteItem.render = getPageRenderer(page.title, pageRouteItem, pageRouteItem, <NavPageMenu
        parent={pageRouteItem}/>);

    pageRouteItem.children = items.map(item => {
        const path = [...pathParts, item.name];
        if (isNavPage(item.target)) {
            const ret = getNavRouteForNavPage(user, item.target, path, pageRouteItem) as NavRouteItem;
            ret.navItem = item;
            return ret;
        }

        const ret = {
            path: pathPartsToUrl(path),
            navPage: page,
            navItem: item,
            render: () => <></>, // Set below
            parent: pageRouteItem,
            children: []
        };
        ret.render = getPageRenderer(item.menuTitle ?? item.title, ret, pageRouteItem, item.target);
        return ret;
    });

    return pageRouteItem;
}

export function flattenNavRouteItem(navRouteItem: NavRouteItem): NavRouteItem[] {
    const subItems = navRouteItem.children.flatMap(item => {
        return flattenNavRouteItem(item);
    });

    subItems.unshift(navRouteItem);

    return subItems;
}
