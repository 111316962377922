import config from '../config';
import Product from "../BusinessObjects/Product";
import Project from "../BusinessObjects/Project";
import Rental from "../BusinessObjects/RentalInterfaces";
import { showMessage, showSpinner } from "../actions/UIActions";
import strings from "../strings";
import {store} from "../store";

const HTTP_STATUS_MULTI_STATUS = 207;
const HTTP_STATUS_BAD_REQUEST = 400;

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export default class Api {
    public static async call(resource: string, method: Method, data?: object): Promise<any> {
        store.dispatch(showSpinner(true));
        return fetch(this.getUrl(resource), this.getInit(method, data))
            .then((response: Response) => {
                store.dispatch(showSpinner(false));
                if (!response.ok) {
                    throw response;
                }
                return response.body !== null ? response.json()
                    .then((result) => {
                            if (response.status === HTTP_STATUS_MULTI_STATUS) {
                                const failures = (result as Object[]).map((item: any, index: number) => {
                                        if (item.status >= HTTP_STATUS_BAD_REQUEST) {
                                            return {
                                                ...item,
                                                index,
                                                ...(data as Object[])[index]
                                            }
                                        }
                                        return null;
                                    }
                                ).filter(x => x != null);
                                if (failures.length > 0) {
                                    throw failures;
                                }
                            }
                            return result;
                        }
                    ) : {result:true};
            })
            .catch((e: any) => {
                store.dispatch(showSpinner(false));
                console.error(e);
                throw e;
            });
    }

    public static getUrl(subPath: string): string {
        return config.api.baseUrl + subPath.replace(/^\/+/, '');
    }

    public static getInit(method: string, jsonData?: object): RequestInit {
        const state = store.getState();
        const headers: HeadersInit = {};

        if (state.oidc.user?.access_token) {
            headers['Authorization'] = 'Bearer ' + state.oidc.user.access_token;
        }

        if (jsonData) {
            headers['Content-Type'] = 'application/json';
        }

        headers['Accept'] = 'application/json';

        return {
            method,
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            redirect: 'follow',
            body: jsonData ? JSON.stringify(jsonData) : null
        };
    }

    private static async search<T>(searchValue: string, path: string): Promise<T[]> {
        const value = searchValue.replace(/\s+/g, ' ');
        return Api.call(`/v1/${path}?query=${encodeURIComponent(value)}`, 'GET')
            .then((result: T[]) => {
                return result;
            })
            .catch((e: any) => {
                if (e instanceof Response) {
                    store.dispatch(showMessage(`${strings.formatString(strings.unableToFetch, path)}: ${e.status} ${e.statusText}`, "error"));
                    return [];
                }
                store.dispatch(showMessage(`${strings.formatString(strings.unableToFetch, strings.searchPaths[path])}: ${e.message}`, "error"));
                return [];
            });
    }

    public static async searchProducts(searchValue: string): Promise<Product[]> {
        return Api.search<Product>(searchValue, "products");
    }

    public static async searchProjects(searchValue: string): Promise<Project[]> {
        return Api.search<Project>(searchValue, "projects");
    }

    public static async searchRentals(searchValue: string): Promise<Rental[]> {
        return Api.search<Rental>(searchValue, "rentals");
    }
}
