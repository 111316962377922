import {routerMiddleware as createRouterMiddleware} from 'connected-react-router'
import {applyMiddleware, compose, createStore, StoreEnhancer} from 'redux';
import {loadUser} from 'redux-oidc';
import {createBrowserHistory} from 'history'
import thunk from 'redux-thunk';
import userManager from "../utils/userManager";
import createRootReducer from "./createRootReducer";
import createLoggerMiddleware from "../utils/createLoggerMiddleware";
import observeStore from "../utils/observeStore";
import AppState from "./appstate";
import {navigationSetRoot} from "../actions/NavigationActions";
import {getNavRouteForNavPage, NavRouteItem} from "../config/navigation/helpers";
import menuRoot from "../config/navigation";
import config from "../config";
import {localStorageMiddleware, readState} from "../utils/localStorageMiddleware";
import {replaceWarehouseState} from "../actions/Warehouse/WarehouseActions";

export const history = createBrowserHistory();

export default function configureStore(preloadedState?: any) {
    let enhancers: StoreEnhancer<any, any>;

    if (config.environment.devMode === "true" && process.env.NODE_ENV !== "test") {
        const composeEnhancers: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        enhancers = composeEnhancers(
            applyMiddleware(
                createRouterMiddleware(history),
                thunk,
                createLoggerMiddleware(),
                localStorageMiddleware()
            )
        );
    } else {
        enhancers = compose(
            applyMiddleware(
                createRouterMiddleware(history),
                thunk,
                localStorageMiddleware()
            )
        );
    }

    const newStore = createStore(
        createRootReducer(history),
        preloadedState,
        enhancers
    );

    loadUser(newStore, userManager);

    observeStore(newStore, (state: AppState) => state.oidc.user, (user) => {
        // Calculate the app menu tree
        if (user) {
            newStore.dispatch(navigationSetRoot(getNavRouteForNavPage(user, menuRoot) as NavRouteItem));
        } else {
            newStore.dispatch(navigationSetRoot(null));
        }
    });
    observeStore(newStore, (state: AppState) => state.oidc.user, (user) => {
        if (user) {
            const userState: AppState = readState(user.profile.sub);
            if (userState && userState.warehouse) {
                replaceWarehouseState(userState.warehouse);
            }
        }
    });

    return newStore;
}

export const store = configureStore();
