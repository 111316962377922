import { combineReducers } from "redux";
import TransferReducer from "./TransferReducer";
import RegisterMaterialReducer from "./RegisterMaterialReducer";
import RentEquipmentReducer from "./RentEquipmentReducer";
import ReceiveDeliveryReducer from "./ReceiveDeliveryReducer";
import ReturnMaterialReducer from "./ReturnMaterialReducer";
import UserReducer from "./UserReducer";
import FoodReducer from "./FoodReducer";

const WarehouseReducer = combineReducers({
    transfer: TransferReducer,
    registerMaterial: RegisterMaterialReducer,
    rentEquipment: RentEquipmentReducer,
    receiveDelivery: ReceiveDeliveryReducer,
    returnMaterial: ReturnMaterialReducer,
    selectedUser: UserReducer,
    foodOrder: FoodReducer,
});

export default WarehouseReducer;
