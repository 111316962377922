import {Popper, PopperProps} from "@material-ui/core";
import React from "react";


export default function AutocompletePopper(props: PopperProps) {

    return (
        <Popper
            {...props}
            disablePortal={true}
            modifiers={
                {
                    flip: {
                        enabled: false
                    },
                }
            }>
            {props.children}
        </Popper>
    )
}
