import AppState from "../store/appstate";
import {Middleware} from "redux";

export const localStorageMiddleware = (): Middleware => {
    return (store: { getState: () => object; }) => (next) => (action) => {
        next(action);
        const state: AppState = store.getState() as AppState;
        const userId = state?.oidc?.user?.profile.sub;
        if (userId) {
            localStorage.setItem(userId, JSON.stringify({
                warehouse: state.warehouse,
            }));
        }
    }
};

export const readState = (userID: string) => {
    const savedState = localStorage.getItem(userID);
    if (savedState) {
        return JSON.parse(savedState);
    }
    return null;
};

