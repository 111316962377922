import LocalizedStrings from 'react-localization';

interface SearchPath {
    [key: string]: string;
}

const strings = new LocalizedStrings({
    en: {
        title: "Mobile",
        errorTitle: "Error",
        yes: "Yes",
        no: "No",
        ok: "OK",
        placeOrder: "Place order",
        removeOrder: "Remove order",
        changeOrder: "Edit order",
        cancelChange: "Cancel edit",
        confirmChange: "Confirm edit",
        personalOrder: "Personal order",
        noPersonalOrder: "No personal order found!",
        guestOrder: "Guest order",
        food: "Food",
        location: "Location",
        comment: "Comment",
        foodComments: "Dietary or other personal requests",
        confirmRemoveOrder: "Are you sure you want to delete the order?",
        returnToWeekView: "Go back to week view",
        deadlinePassed: "The Deadline has passed",
        timeLeft: "Time left",
        hours: "Hours",
        minutes: "Minutes",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        menu: {
            title: "Menu"
        },
        product: "Product",
        project: "Project",
        scanProduct: "Scan product",
        searchProduct: "Search product",
        searchProject: "Search project",
        contract: "Accord",
        addition: "Addition",
        chooseProduct: "Choose Product",
        currentProductPosition: "Current product position",
        noCurrentProductPosition: "Current product position not found",
        newProductPosition: "New product position",
        newProjectPosition: "New project position",
        contractAdditionType: "Additiontype",
        otherTools: 'Other tools',
        links: {
            marto: 'Project Management: Marto',
            ontime: 'Time reporting: OnTime',
            sympa: 'HR-matters: Sympa',
            pro24: 'Occupational Safety: PRO24',
            ilmoituskanava: 'Notification channel'
        },
        settings: "Settings",
        logOut: "Log out",
        login: "Log in",
        loading: "Loading...",
        warehouse: {
            register: "Register material on a project",
            registerMenuTitle: "Register material",
            transfer: "Transfer material between warehouses",
            transferMenuTitle: "Transfer material",
            lend: "Lend or return equipment",
            receive: "Receive a delivery",
            returnMaterial: "Return material from a project",
            productsWereTransferred: "Products were transferred",
            materialWasReturned: "Material was returned",
            equipmentWasRented: "Equipment rented",
            orderFood: "Order food"
        },
        pages: {
            root: {
                title: "Sundström Mobile",
                description: "Choose the application you want to use."
            },
            warehouse: {
                title: "Warehouse"
            }
        },
        pageLoadErrorMessage: "An error occured loading the page. Please check that you have an active Internet connection and try again.",
        defaultErrorMessage: "An error occured. Please check your input and that you have an active Internet connection and try again.",
        amount: "Amount",
        add: "Add",
        cancel: "Cancel",
        nothingTransferred: "Added products will be shown here",
        nothingRegistered: "Added products will be shown here",
        noWarehousesFound: "No Warehouses Found",
        noContractAdditionTypesFound: "No Addition Types Found",
        confirm: "Confirm",
        update: "Update",
        materialList: "Material list",
        removeFromMaterialList: "Remove from material list",
        lastUpdatedBy: "Last updated by",
        datePrefix: "",
        rentEquipmentDestination: {
            destinationWarehouse: "New warehouse location",
            destinationProject: "New project location",
            warehouse: "Warehouse",
            project: "Project",
            personal: "Personal equipment",
            noPossibleDestinationWarehousesFound: "No possible warehouse locations found"
        },
        searchPaths: {
            "products": "products",
            "projects": "projects",
            "equipment": "equipment",
            "purchaseorder": "purchaseorder"
        } as SearchPath,
        unableToFetch: "Could not fetch {0}",
        unableToTransferProducts: "Note! Could not transfer products",
        unableToReturnMaterial: "Note! Could not return material",
        contactResponsible: "Please contact:",
        unableToRegisterRental: "Could not register rental",
        receiveDelivery: {
            supplier: "Supplier",
            correctAmount: "Correct the amount for",
            amount: "",
            emptyMaterialList: "No material was found for the delivery",
            ordernumber: "Ordernumber",
            searchDelivery: "Search delivery",
            instructionString: "Check all amounts for the delivery and adjust when needed. When done, press Confirm",
            deliveryRegistered: "Delivery registered",
            couldNotRegisterDelivery: "Could not register delivery",
            deliveryAlreadyConfirmed: "Delivery has already been confirmed",
            deliveryNotReady: "This order is not ready for delivery"
        },
        profile: {
            title: "Profile",
            yourData: "Your data",
            myRentalButtonText: "Your rentals",
            myTransfersButtonText: "Your transactions",
            goHome: "Back to main menu",
            myRentals: "My rentals",
            noRentalsFound: "No rentals found in your name",
            rentalCreatedBy: "Created by",
            myTransactions: "My transactions",
            noTransactionsFound: "No transactions found in your name",
        },
        yourExperienceHasBeenImproved: "Update available",
        updateDialogText: "There is a new version for the application available. Please press OK to update",
        returnMaterial: "Return",
        chooseScanningCamera: "Choose Camera for Scanning",
        selectCamera: "Select Camera",
        chooseScanningCameraText: "Choose Camera for Scanning. If not selected, the app will automatically pick a camera for you.",
        noDefaultCameraSaved: "No default camera saved",
        defaultCameraSaved: "Default camera saved. The app will now use this one to scan codes",
        defaultCameraRemoved: "Default camera removed. The app will now pick one for you.",
        SaveNewContractAdditionType: "Save New Contract Addition Type",
        errorSavingContractAdditionType: "There was an error saving you contract addition type. Please try again.",
        chooseProjectFirst: "Please, Choose Project First!",
        addNewContractAdditionType: "Add new Contract Addition Type",
        choiceNotFoundAddNew: "If you can't find the desired Contract Addition in the drop down you can add a new one here.",
        contractAdditionTooShort: "Please write a longer name for the Contract Addition Type!",
        backOrder: "Backorder",
        userDropdown: {
            showUserDropdown: "Rent equipment as another user",
            noUsersFound: "No users found",
        },
    },

    sv: {
        title: "Mobile",
        errorTitle: "Fel",
        yes: "Ja",
        no: "Nej",
        ok: "OK",
        placeOrder: "Gör beställning",
        removeOrder: "Ta bort beställning",
        changeOrder: "Förändra beställning",
        cancelChange: "Avbryt ändring",
        confirmChange: "Bekräfta ändringen",
        personalOrder: "Personlig beställning",
        noPersonalOrder: "Ingen personlig beställning hittades!",
        guestOrder: "Gäst beställning",
        food: "Maträtt",
        location: "Plats",
        comment: "Anmärkning",
        foodComments: "Kost eller andra personliga önskemål",
        confirmRemoveOrder: "Är du säker att du vill ta bort beställningen?",
        returnToWeekView: "Återvänd till veckovyn",
        deadlinePassed: "Tidsgränsen har uppnåtts",
        timeLeft: "Återstående tid",
        hours: "Timmar",
        minutes: "Minuter",
        monday: "Måndag",
        tuesday: "Tisdag",
        wednesday: "Onsdag",
        thursday: "Torsdag",
        friday: "Fredag",
        menu: {
            title: "Meny"
        },
        product: "Produkt",
        project: "Projekt",
        scanProduct: "Skanna produkt",
        searchProduct: "Sök produkt",
        searchProject: "Sök projekt",
        contract: "Ackord",
        addition: "Tillägg",
        chooseProduct: "Välj produkt",
        currentProductPosition: "Produktens nuvarande position",
        noCurrentProductPosition: "Nuvarande position hittades inte",
        newProductPosition: "Produktens nya position",
        newProjectPosition: "Produktens nya projekt",
        contractAdditionType: "Tilläggstyp",
        otherTools: 'Övriga verktyg',
        links: {
            marto: 'Projekthantering: Marto',
            ontime: 'Tidsrapportering: OnTime',
            sympa: 'HR-ärenden: Sympa',
            pro24: 'Arbetssäkerhet: PRO24',
            ilmoituskanava: 'Anmälningskanal'
        },
        settings: "Inställningar",
        logOut: "Logga ut",
        login: "Logga in",
        loading: "Laddar...",
        warehouse: {
            register: "Registrera material på ett projekt",
            registerMenuTitle: "Registrera material",
            transfer: "Flytta material mellan lager",
            transferMenuTitle: "Flytta material",
            lend: "Hyr eller returnera utrustning",
            receive: "Ta emot leverans",
            returnMaterial: "Returnera material från projekt",
            productsWereTransferred: "Materialet flyttades",
            materialWasReturned: "Materialet returnerades",
            equipmentWasRented: "Utrustning hyrd",
            orderFood: "Beställ mat"
        },
        pages: {
            root: {
                title: "Sundström Mobile",
                description: "Välj den applikation du vill använda."
            },
            warehouse: {
                title: "Lagerhantering"
            }
        },
        pageLoadErrorMessage: "Ett fel inträffade när sidan skulle laddas. Var god kontrollera att du har en aktiv Internetanslutning och försök igen.",
        defaultErrorMessage: "Ett fel inträffade. Var god kontrollera din data och att du har en aktiv Internetanslutning och försök igen.",
        amount: "Mängd",
        add: "Lägg till",
        cancel: "Avbryt",
        nothingTransferred: "Material du lägger till för att flytta kommer att visas här",
        nothingRegistered: "Material du lägger till för att registreras kommer att visas här",
        noWarehousesFound: "Inga lager hittades",
        noContractAdditionTypesFound: "Inga tilläggstyper hittades",
        confirm: "Bekräfta",
        update: "Uppdatera",
        materialList: "Materiallista",
        removeFromMaterialList: "Ta bort från materiallista",
        lastUpdatedBy: "Senast uppdaterad av",
        datePrefix: "den",
        rentEquipmentDestination: {
            destinationWarehouse: "Ny lagerposition",
            destinationProject: "Ny projektposition",
            warehouse: "Lager",
            project: "Projekt",
            personal: "Personlig utr.",
            noPossibleDestinationWarehousesFound: "Inga möjliga lagerpositioner hittades"
        },
        searchPaths: {
            "products": "produkter",
            "projects": "projekt",
            "equipment": "utrustning",
            "purchaseorder": "inköpsorder"
        } as SearchPath,
        unableToFetch: "Kunde inte hämta {0}",
        unableToTransferProducts: "OBS! Kunde inte flytta produkter",
        unableToReturnMaterial: "Kunde inte returnera material",
        unableToRegisterRental: "Kunde inte registrera uthyrning",
        contactResponsible: "Viktigt: Kontakta ",
        receiveDelivery: {
            supplier: "Leverantör",
            correctAmount: "Korrigera mängd för",
            amount: "",
            emptyMaterialList: "Inga material hittades för inköpsordern",
            ordernumber: "Ordernummer",
            searchDelivery: "Sök leverans",
            instructionString: "Kontrollera alla mängder för materialet du tar emot och justera enligt behov. När du är klar, tryck Bekräfta.",
            deliveryRegistered: "Leveransen registrerad",
            couldNotRegisterDelivery: "Kunde inte registrera leverans",
            deliveryAlreadyConfirmed: "Inköpsordern har redan tagits emot",
            deliveryNotReady: "Denna order är inte klar för leverans"
        },
        profile: {
            title: "Användarprofil",
            yourData: "Dina uppgifter",
            myRentalButtonText: "Dina uthyrningar",
            myTransfersButtonText: "Dina transaktioner",
            goHome: "Tillbaka till appen",
            myRentals: "Produkter du hyrt",
            noRentalsFound: "Hittade inga produkter som du hyrt",
            rentalCreatedBy: "Skapat av",
            myTransactions: "Mina transaktioner",
            noTransactionsFound: "Hittade inga transaktioner",
        },
        returnMaterial: "Returnera",
        yourExperienceHasBeenImproved: "Uppdatering finns tillgänglig",
        updateDialogText: "Appen har uppdaterats. När du klickar på OK hämtas nyaste versionen",
        chooseScanningCamera: "Välj kamera för skanning",
        selectCamera: "Välj kamera",
        chooseScanningCameraText: "Välj kamera för skanning. Om ingen kamera väljs kommer appen att välja automatiskt.",
        noDefaultCameraSaved: "Ingen förvald kamera sparad",
        defaultCameraSaved: "Förvald kamera sparad. Appen kommer nu att använda denna kamera för skanningen",
        defaultCameraRemoved: "Förvald kamera raderad. Appen väljer själv rätt kamera för skanningen",
        SaveNewContractAdditionType: "Spara ny tilläggstyp",
        errorSavingContractAdditionType: "Något gick fel då tilläggstypen skulle sparas. Försök igen.",
        chooseProjectFirst: "Var god, välj ett projekt först!",
        addNewContractAdditionType: "Lägg till en ny tilläggstyp.",
        choiceNotFoundAddNew: "Om du inte hittade tilläggstypen i listan kan du spara en ny här.",
        contractAdditionTooShort: "Var god skriv ett längre namn på tilläggstypen.",
        backOrder: "Restorder",
        userDropdown: {
            showUserDropdown: "Hyra som en annan användare",
            noUsersFound: "Inga användare hittades",
        },
    },

    fi: {
        title: "Mobile",
        errorTitle: "Virhe",
        yes: "Kyllä",
        no: "Ei",
        ok: "OK",
        placeOrder: "Tee tilaus",
        removeOrder: "Poista tilausta",
        changeOrder: "Muokkaa tilausta",
        cancelChange: "Peruuta muokkaus",
        confirmChange: "Vahvista muokkaus",
        personalOrder: "Henkilökohtainen tilaus",
        noPersonalOrder: "Henkilökohtaista tilausta ei löytynyt!",
        guestOrder: "Vierastilaus",
        food: "Ruoka",
        location: "Sijainti",
        comment: "Huomautus",
        foodComments: "Ruokavalio tai muut henkilökohtaiset toiveet",
        confirmRemoveOrder: "Haluatko varmasti poistaa tilausta?",
        returnToWeekView: "Palaa viikkonäkymään",
        deadlinePassed: "Määräaika on päättynyt",
        timeLeft: "Jäljellä oleva aika",
        hours: "Tunteja",
        minutes: "Minuuttia",
        monday: "Maanantai",
        tuesday: "Tiistai",
        wednesday: "Keskiviikko",
        thursday: "Torstai",
        friday: "Perjantai",
        menu: {
            title: "Valikko"
        },
        product: "Tuote",
        project: "Projekti",
        searchProject: "Etsi projekti",
        contract: "Urakka",
        addition: "Lisätyö",
        scanProduct: "Lue tuotekoodi",
        searchProduct: "Etsi tuote",
        chooseProduct: "Valitse tuote",
        currentProductPosition: "Tuotteen tämänhetkinen sijainti",
        noCurrentProductPosition: "Tämänhetkistä sijaintia ei löytynyt",
        newProductPosition: "Tuotteen uusi sijainti",
        newProjectPosition: "Tuotteen uusi projekti",
        contractAdditionType: "Lisätyötyyppi",
        otherTools: 'Muut palvelut',
        links: {
            marto: 'Projektihallinta: Marto',
            ontime: 'Tuntiraportointi: OnTime',
            sympa: 'HR-asiat: Sympa',
            pro24: 'Työturvallisuus: PRO24',
            ilmoituskanava: 'Ilmoituskanava'
        },
        settings: "Asetukset",
        logOut: "Kirjaudu ulos",
        login: "Kirjaudu sisään",
        loading: "Loading...",
        warehouse: {
            register: "Tuotteen rekisteröinti projektille",
            registerMenuTitle: "Rekisteröi tuote",
            transfer: "Tuotteen siirto varastojen välillä",
            transferMenuTitle: "Siirrä tuote",
            lend: "Vuokraa tai palauta tarvikkeita",
            receive: "Lähetyksen vastaanotto",
            returnMaterial: "Tavaroiden palautus projektilta",
            productsWereTransferred: "Tuotteet siirretty",
            materialWasReturned: "Tuotteet palautettu",
            equipmentWasRented: "Tarvike vuokrattu",
            orderFood: "Tilaa ruokaa"
        },
        pages: {
            root: {
                title: "Sundström Mobile",
                description: "Valitse haluamasi sovellus"
            },
            warehouse: {
                title: "Varastokäsittely"
            }
        },
        pageLoadErrorMessage: "Tapahtui virhe ladatessa sivua. Ole hyvä ja tarkista, että verkkoyhteytesi on aktiivinen ja yritä uudelleen.",
        defaultErrorMessage: "Tapahtui virhe. Ole hyvä ja tarkista syöttösi ja että verkkoyhteytesi on aktiivinen ja yritä uudelleen.",
        amount: "Määrä",
        add: "Lisää",
        cancel: "Peruuta",
        nothingTransferred: "Siirrettävät tuotteet lisätään tälle listalle",
        nothingRegistered: "Rekisteröitävät tuotteet lisätään tälle listalle",
        noWarehousesFound: "Varastoja ei löytynyt",
        noContractAdditionTypesFound: "Lisätyötyyppejä ei löytynyt",
        confirm: "Vahvista",
        update: "Päivitä",
        materialList: "Tuotelista",
        removeFromMaterialList: "Poista tuotelistasta",
        lastUpdatedBy: "Viimeksi päivittynyt käyttäjä",
        datePrefix: "",
        rentEquipmentDestination: {
            destinationWarehouse: "Uusi varastosijainti",
            destinationProject: "Uusi projektisijainti",
            warehouse: "Varasto",
            project: "Projekti",
            personal: "Henk.koht. varuste",
            noPossibleDestinationWarehousesFound: "Mahdollisia varastosijainteja ei löytynyt"
        },
        unableToFetchProjects: "Projektia ei pystytty hakemaan",
        unableToTransferProducts: "HUOM! Tuotteita ei pystytty siirtämään",
        unableToReturnMaterial: "Tuotteita ei pystytty palauttamaan",
        unableToRegisterRental: "Vuokrausta ei pystytty rekisteröimään",
        contactResponsible: "Ota yhteyttä: ",
        searchPaths: {
            products: "tuotteita",
            projects: "projekteja",
            equipment: "tarvikkeita",
            purchaseorder: "tilaus"
        } as SearchPath,
        unableToFetch: "Ei voida hakea {0}",
        receiveDelivery: {
            supplier: "Toimittaja",
            correctAmount: "Korjaa tuotteen",
            amount: "määrä",
            emptyMaterialList: "Tilauksella ei ole tuotteita",
            ordernumber: "Tilausnumero",
            searchDelivery: "Hae lähetystä",
            instructionString: "Tarkista lähetyksen tiedot ja korjaa tuotemäärät tarvittaessa. Kun olet valmis, paina Vahvista",
            deliveryRegistered: "Lähetys vahvistettu",
            couldNotRegisterDelivery: "Lähetystä ei voitu vahvistaa",
            deliveryAlreadyConfirmed: "Lähetys on jo vastaanotettu",
            deliveryNotReady: "Tämä tilaus ei ole valmis vastaanotettavaksi"
        },
        profile: {
            title: "Tilin asetukset",
            yourData: "Tietosi",
            myRentalButtonText: "Vuokraamasi tuotteet",
            myTransfersButtonText: "Tekemäsi tavarasiirrot",
            goHome: "Päävalikkoon",
            myRentals: "Vuokraamani tuotteet",
            noRentalsFound: "Vuokraamiasi tuotteita ei löytynyt",
            rentalCreatedBy: "Luonut",
            myTransactions: "Tekemäni tavarsiirrot",
            noTransactionsFound: "Tavarasiirtoja ei löytynyt",
        },
        yourExperienceHasBeenImproved: "Päivitys saatavilla",
        updateDialogText: "Sovellus on päivitetty. Paina OK ladataksesi uusimman version",
        returnMaterial: "Palauta",
        chooseScanningCamera: "Valitse kamera koodien lukemiseen",
        selectCamera: "Valitse kamera",
        chooseScanningCameraText: "Valitse kamera koodien lukemiseen. Jos valinta ei tehdä, appi valitsee itse automaattisesti.",
        noDefaultCameraSaved: "Oletuskameraa ei vielä tallennettu",
        defaultCameraSaved: "Oletuskamera tallennettu. Appi käyttää nyt tämä kamera skannaamiseen",
        defaultCameraRemoved: "Oletuskamera poistettu. Appi valitsee itse kameraa skannausta varten.",
        SaveNewContractAdditionType: "Tallenna uusi lisätyötyyppi",
        errorSavingContractAdditionType: "Virhe lisätyötyypin tallentamisessa. Yritä uudelleen.",
        chooseProjectFirst: "Ystävällisesti, valitse ensin projekti!",
        addNewContractAdditionType: "Lisää uusi lisätyötyyppi",
        choiceNotFoundAddNew: "Jos oikea lisätyötyyppi ei löytynyt listasta voit lisätä uuden tästä",
        contractAdditionTooShort: "Ystävällisesti kirjoita pitempi nimi liätyötyypille.",
        backOrder: "Jälkitoimitus",
        userDropdown: {
            showUserDropdown: "Vuokraa toisena käyttäjänä",
            noUsersFound: "Käyttäjiä ei löytynyt",
        },
    },
});

export default strings;
