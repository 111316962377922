import React, {ReactNode} from 'react';
import MenuBar from "./MenuBar";
import { LinksSection } from './ExternalLinks';
import strings from "../strings";

interface LayoutProps {
    children: ReactNode;
    showMenu: boolean;
    className?: string;
    menuTitle?: string;
}

const Layout = (props: LayoutProps) => {
    const isWarehousePage = props.menuTitle === strings.pages.warehouse.title;

    return (
        <div className={"body" + (props.className ? " " + props.className : "")}>
            {props.showMenu && <MenuBar title={props.menuTitle}/>}
            <div className="main">
                {props.children}
                {isWarehousePage ? <LinksSection /> : null}
            </div>
        </div>
    );
}

export default Layout;
