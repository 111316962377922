import {combineReducers} from 'redux';
import {ActionTypes} from "../../actions/Warehouse/FoodOrderActions";

interface UserOrder {
    id: number;
    food: string;
    location: string;
    comment: string;
    date: Date;
    guest: boolean;
}

interface UserOrderState {
    orders: UserOrder[];
}

const initialUserOrderState: UserOrderState = {
    orders: [],
};

const userOrdersReducer = (
    state = initialUserOrderState,
    action: any
): UserOrderState => {
    switch (action.type) {
        case ActionTypes.ADD_USER_ORDER:
            const newOrder = {
                id: action.payload.id,
                food: action.payload.food,
                location: action.payload.location,
                comment: action.payload.comment,
                date: action.payload.date,
                guest: action.payload.guest,
            };
            return {
                ...state,
                orders: [...state.orders, newOrder],
            };
        case ActionTypes.DELETE_USER_ORDER:
            return {
                ...state,
                orders: state.orders.filter((order) => order.id !== action.payload),
            };
        case ActionTypes.UPDATE_USER_ORDER:
            return {
                ...state,
                orders: state.orders.map((order) =>
                    order.id === action.payload.id
                        ? {
                            ...order,
                            food: action.payload.food,
                            location: action.payload.location,
                            comment: action.payload.comment,
                        }
                        : order
                ),
            };
        case ActionTypes.SET_USER_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        default:
            return state;
    }
};

interface Location {
    id: string;
    name: string;
}

interface LocationsState {
    locations: Location[];
}

const initialLocationsState: LocationsState = {
    locations: [],
};

const locationsReducer = (state = initialLocationsState, action: any): LocationsState => {
    if (action.type === ActionTypes.SET_LOCATIONS) {
        return {
            ...state,
            locations: action.payload,
        };
    }
    return state;
};

interface FoodOption {
    id: string;
    name: string;
}

interface FoodOptionsState {
    foodOptions: FoodOption[];
}

const initialFoodOptionsState: FoodOptionsState = {
    foodOptions: [],
};

const foodOptionsReducer = (state = initialFoodOptionsState, action: any): FoodOptionsState => {
    if (action.type === ActionTypes.SET_FOOD_OPTIONS) {
        return {
            ...state,
            foodOptions: action.payload,
        }
    }
    return state;
};

interface MealItem {
    id: string;
    name: string;
}

interface MealItemsState {
    mealItems: MealItem[];
}

const initialMealItemsState: MealItemsState = {
    mealItems: [],
};

const mealItemsReducer = (state = initialMealItemsState, action: any): MealItemsState => {
    if (action.type === ActionTypes.SET_MEAL_ITEMS) {
        return {
            ...state,
            mealItems: action.payload,
        };
    }
    return state;
};

// Food Reducers
const FoodReducer = combineReducers({
    locations: locationsReducer,
    foodOptions: foodOptionsReducer,
    userOrders: userOrdersReducer,
    mealItems: mealItemsReducer,
});

export default FoodReducer;
