import Project from "../BusinessObjects/Project";
import { getProjectTypeText } from "../BusinessObjects/ProjectType";
import React from "react";
import { Icon } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";

const ProjectSearchListItem = (props: { option: Project, refreshOption?: () => void }) => {

    const refreshClicked = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        if(props.refreshOption) props.refreshOption();
    }
  
    const refreshIcon = props.refreshOption ?
                            <span className={"refresh-project"} onClick={(e) => refreshClicked(e)}>
                                <Icon  component={Refresh} fontSize={"large"} /> 
                            </span>
                        : null;

     return (<div className={"project"}>
                <div className={"customer-name-container"}>
                    <span className={"name"}>{props.option.name}</span>
                    <span className={"customer"}>{props.option.customerName}</span>
                </div>
                <div className={"project-info-container"}>
                    <span className={"type"}>{getProjectTypeText(props.option.projectType)}</span>
                    <span className={"number"}>{props.option.id}</span>
                </div>
                {refreshIcon}
            </div>);
    };

export default ProjectSearchListItem;
