import initialBaseState from "./initialState";
import {CostTypes} from "../../components/CostTypeChoice";
import RegisterMaterialState from "./RegisterMaterialState";
import {ActionTypes, ProductAction, RegisterMaterialAction} from "../../actions/Warehouse/RegisterMaterialActions";

const initialState: RegisterMaterialState = {
    ...initialBaseState,
    currentProject: null,
    materialRegistrations: [],
    costType: CostTypes.contract,
    contractAddition: null
};


const RegisterMaterialReducer = (state: RegisterMaterialState = initialState, action: RegisterMaterialAction) => {
    switch (action.type) {
        case ActionTypes.PRODUCT_CHOSEN:
            return (
                action.product !== state.currentProduct
                && (action.product?.id !== state.currentProduct?.id))
                ?
                {
                    ...state,
                    currentProduct: (action as ProductAction).product,
                    sourceWarehouse: null
                }
                : state;
        case ActionTypes.SEARCH_RESULT_UPDATED:
            return {
                ...state,
                searchResults: action.result
            };
        case ActionTypes.SOURCE_WAREHOUSE_SELECTED:
            return {
                ...state,
                sourceWarehouse: action.inventory
            };
        case ActionTypes.PROJECT_CHOSEN:
            return {
                ...state,
                currentProject: action.project,
                costType: null,
                contractAddition: null
            };
        case ActionTypes.COST_TYPE_CHOSEN:
            return {
                ...state,
                costType: action.costType
            };
        case ActionTypes.REGISTRATION_ADDED:
            return {
                ...state,
                materialRegistrations: [...state.materialRegistrations, action.materialRegistration],
                sourceWarehouse: null,
                currentProduct: null,
                amount: 1,
                contractAddition: null,
                currentRegistrationInfo: null
            };
        case ActionTypes.CONTRACT_ADDITION_TYPE_CHOSEN :
            return {
                ...state,
                contractAddition: action.contractAddition
            };
        case ActionTypes.AMOUNT_CHANGED:
            return {
                ...state,
                amount: action.amount
            };
        case ActionTypes.REGISTRATION_SELECTED:
            return {
                ...state,
                sourceWarehouse: action.registrationInfo.inventorySource,
                currentProject: action.registrationInfo.project,
                currentProduct: action.registrationInfo.product,
                amount: action.registrationInfo.amount,
                costType: action.registrationInfo.costType,
                contractAddition: action.registrationInfo.contractAdditionType,
                currentRegistrationInfo: action.registrationInfo
            };
        case ActionTypes.REGISTRATION_CHANGED:
            return {
                ...state,
                materialRegistrations: state.materialRegistrations.map(
                    (item) => item === action.current
                        ? action.newData
                        : item),
                sourceWarehouse: null,
                currentProduct: null,
                amount: 1,
                costType: null,
                contractAddition: null,
                currentRegistrationInfo: null

            };
        case ActionTypes.REGISTRATION_REMOVED:
            return {
                ...state,
                materialRegistrations: state.materialRegistrations.filter(
                    (item) => item !== action.registrationInfo),
                sourceWarehouse: null,
                currentProduct: null,
                currentProject: (state.materialRegistrations && state.materialRegistrations.length > 1) ? state.currentProject : null,
                amount: 1,
                costType: null,
                contractAddition: null,
                currentRegistrationInfo: null
            };
        case ActionTypes.REGISTRATION_SUCCEEDED:
            return {
                ...state,
                ...initialState
            };
        case ActionTypes.STATE_REPLACED:
            return action.newState ?? state;
        case ActionTypes.CONTRACT_ADDITIONS_CHANGED:
            return {
                ...state,
                contractAdditions: action.contractAdditions
            };
        default:
            return state;
    }
};

export default RegisterMaterialReducer;
